import Burger from "@animated-burgers/burger-squeeze"
import "@animated-burgers/burger-squeeze/dist/styles.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Button, Menu } from "../components"
import {
  HeaderWrapper,
  LogoMenuWrapper,
  MenuButtonWrapper,
  P,
} from "../elements"

export const Header = () => {
  const isMedium = useMediaQuery({ minDeviceWidth: "64rem" })
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "ipp-logo.svg" }) {
        publicURL
      }
    }
  `)
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu",
  })
  const [disabled, setDisabled] = useState(false)
  const handleMenu = () => {
    disableMenu()
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close",
      })
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu",
      })
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close",
      })
    }
  }
  const disableMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1200)
  }

  return (
    <>
      <Menu state={state} />
      <HeaderWrapper>
        <LogoMenuWrapper>
          <Link to="/">
            <img src={data.logo.publicURL} alt="IPP Logo" />
          </Link>
          <MenuButtonWrapper onClick={handleMenu} disabled={disabled}>
            <Burger
              style={{ fontSize: "7px" }}
              isOpen={state.clicked}
              Component="button"
              type="button"
            />
            <P>{state.menuName}</P>
          </MenuButtonWrapper>
        </LogoMenuWrapper>
        {isMedium && (
          <Button className="startproject" variant="solid" to="/start-a-project">
            Start A Project
          </Button>
        )}
      </HeaderWrapper>
    </>
  )
}

import { FormControlLabel, FormHelperText, MenuItem } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { Button } from "../components"
import { FormWrapper, IPPCheckbox, P } from "../elements"
import useFormProject from "../hooks/useFormProject"
import validateProject from "../utils/validateFormProject"
import { useEffect, useState } from "react"
import "../stylesheets/startproj.css"


export const ProjectForm = () => {

  const { handleChange, handleSubmit, values, terms, errors } = useFormProject(
    submit,
    validateProject
  )

  //const showRes = useState(false)

  //"For submitted message"
  const [show, setMsg] = useState({
    msg:'',
    submitted:false
  })

  function submit() {
    console.log("hii")
  }

  const options2 = [
    {
      value: "Business Transformation",
      label: "Business Transformation",
    },
    {
      value: "Marketing Transformation",
      label: "Marketing Transformation",
    },
    {
      value: "Experience Transformation",
      label: "Experience Transformation",
    },
  ]

  const options = [
    {
      value: "Search Engine",
      label: "Search Engine",
    },
    {
      value: "Ads",
      label: "Ads",
    },
    {
      value: "Social media",
      label: "Social media",
    },
    {
      value: "Referral from another",
      label: "Referral from another",
    },
  ]

  var click=false

  function clickd() {

    if (Object.keys(errors).length === 0){
        setMsg({
          msg: 'Form submitted successfully!',
          submitted:true
        })
    }
    else
    {
      setMsg({
        msg: 'Please fill out all fields correctly!',
        submitted:true
      })
    }

  }


  return (
    <FormWrapper noValidate onSubmit={handleSubmit} autoComplete="off">
    <TextField
      className="text-field projectinp"
      required
      name="name"
      label="Your Name"
      error={show.submitted && errors.name ? true : false}
      onChange={handleChange}
      value={values.name}
      helperText={show.submitted && errors.name ? errors.name : ""}
    />
    <TextField
      className="text-field projectinp"
      name="companyName"
      required
      label="Name of your company"
      error={show.submitted && errors.companyName ? true : false}
      onChange={handleChange}
      value={values.companyName}
      helperText={show.submitted && errors.companyName ? errors.companyName : ""}
    />
    <TextField
      className="text-field full-width projectinp"
      name="email"
      required
      label="Email Address"
      error={show.submitted && errors.email ? true : false}
      onChange={handleChange}
      value={values.email}
      helperText={show.submitted && errors.email ? errors.email : ""}
    />

    <P weight="bold" size="large">
      How Did You Find Out About Us?
    </P>
    <TextField
      className="text-field projectinp"
      name="aboutus"
      select
      required
      error={show.submitted && errors.aboutus ? true : false}
      onChange={handleChange}
      value={values.aboutus}
      helperText={show.submitted && errors.aboutus ? errors.aboutus : ""}
      label="Select"
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    <TextField
      className="text-field full-width projectinp"
      label="Tell Us about your project*"
      error={show.submitted && errors.projectabout ? true : false}
      name="projectabout"
      onChange={handleChange}
      value={values.projectabout}
      required
      helperText={show.submitted && errors.projectabout ? errors.projectabout : ""}
      multiline
    />
    <P weight="bold" size="large">
      What type of project are you interested in?
    </P>
    <TextField
      className="text-field projectinp"
      name="interests"
      select
      required
      error={show.submitted && errors.interests ? true : false}
      onChange={handleChange}
      value={values.interests}
      helperText={show.submitted && errors.interests ? errors.interests : ""}
      label="Select"
    >
      {options2.map(option2 => (
        <MenuItem key={option2.value} value={option2.value}>
          {option2.label}
        </MenuItem>
      ))}
    </TextField>
   
    
    <FormControlLabel
      className="full-width projectinp"
      control={
        <IPPCheckbox
          value={values.terms}
          required
          onChange={handleChange}
          name="terms"
        />
      }
      label="I agree to receive occasional It’s Pixel Perfect newsletters containing news and advice on creating personal and business progress through digital transformation, experience and marketing."
    />
    {errors.terms && <FormHelperText error>{show.submitted && errors.terms ? errors.terms : ""}</FormHelperText>}

    <FormHelperText className="projectinp" id="">{show.msg}</FormHelperText>
    
    <Button className="submit-button" variant="solid"  onClick={clickd}>
      Submit
    </Button>

    <P className="full-width projectinp" size="xSmall" >
      We will use your contact details to contact you directly about potential
      cooperation opportunities now or in the future.
    </P>


  </FormWrapper>
  )
}

import React from "react"

import { BenefitsCardWrapper, P } from "../elements"

export const BenefitsCard = ({ benefit }) => {
  const { title, description } = benefit
  return (
    <BenefitsCardWrapper className="beneftsCard">
      <P size="large" style={{ fontWeight: 900 }} margin="0 0 23px 0">
        {title}
      </P>
      <P style={{ color: "#BABABA" }}>{description}</P>
    </BenefitsCardWrapper>
  )
}

import styled from "@emotion/styled"
import Img from "gatsby-image"

export const LocationCardWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 25px 0;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
`
export const LocationCardDescription = styled.div`
  width: 410px;
`
export const LocationImageWrapper = styled.div`
  width: 100%;
`

export const LocationImage = styled(Img)`
  width: 100%;
  height: 100%;
`

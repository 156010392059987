import styled from "@emotion/styled"

export const P = styled.p`
  color: ${props => (props.black ? "black" : "white")};
  margin: ${props => (props.margin ? props.margin : 0)};
  font-size: ${props => {
    switch (props.size) {
      case "medium":
        return "1.125rem"
      case "small":
        return "1rem"
      case "xSmall":
        return "0.875rem"
      case "xxSmall":
        return "0.5rem"
      case "large":
        return "1.5rem"
      default:
        return "1.125rem"
    }
  }};

  font-family: ${props => props.theme.fonts.body};
  line-height: ${props => {
    switch (props.size) {
      case "medium":
        return "1.75rem"
      case "small":
        return "1.375rem"
      case "xSmall":
        return "1.125rem"
      case "xxSmall":
        return "1rem"
      case "large":
        return "2rem"
      default:
        return "1.75rem"
    }
  }};

  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : "none"};

  font-weight: ${props => {
    switch (props.weight) {
      case "normal":
        return 400
      case "light":
        return 300
      case "thin":
        return 100
      case "bold":
        return 900
      default:
        return 400
    }
  }};
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
`

export const H1 = styled.h1`
  color: ${props => (props.black ? "black" : "white")};
  display: inline-block;
  font-family: ${props => props.theme.fonts.heading};
  font-size: ${props => (props.large ? "1.8125rem" : "1.8125rem")};
  font-weight: 700;
  position: relative;
  margin: ${props => (props.margin ? props.margin : 0)};
  z-index: 2;
  @media ${props => props.theme.breakpoints.medium} {
    grid-column: ${props => (props.grid === "2x2" ? "1 / 3" : "auto")};
    font-size: ${props => (props.large ? "3.375rem" : "2.1875rem")};
    line-height: ${props => (props.large ? "60px" : "41px")};
  }
`
export const span = H1.withComponent("span")
export const Strike = styled(span)`
  &:after {
    background: ${props => props.theme.colors.purple};
    bottom: 40%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    width: 0;
    z-index: -1;
    transition: width 1s;
  }
  &:hover::after {
    width: 100%;
  }
`

export const H2 = styled.h2`
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  display: ${props => (props.hideH2 ? "none" : "block")};
  color: ${props => (props.gray ? "#a2a2a2" : "white")};
  margin: ${props => (props.margin ? props.margin : "0.625rem 0 0 0")};
  font-size: 2.1875rem;
`
export const H3 = H2.withComponent("h3")

import gsap from "gsap"
import React, { useEffect, useRef } from "react"
import {
  Container,
  Item,
  List,
  MenuLayer,
  MenuLinks,
  MenuWrapper,
  MLink,
  Nav,
  SecondaryBackgroundColor,
} from "../elements"
import {Button} from "../components"
import {
  staggerReveal,
  staggerRevealClose,
  staggerText,
} from "../utils/animations"

export const Menu = ({ state }) => {
  let menu = useRef(null)
  let revealMenu = useRef(null)
  let revealMenuBackground = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)
  let line9 = useRef(null)

    // Requiring function causes error during builds
  // as the code tries to reference window


  // Wrap the require in check for window


  var html =''
  var body=''
  if (typeof window !== 'undefined') {
    html = document.querySelector('html')
    body = document.querySelector('body')
  }
  else
  {
    html = ''
    body=''
  }


  //If menu is open, page is unscrollable
  useEffect(() => {
    state.clicked ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible')
    state.clicked ? (body.style.overflow = 'hidden') : (body.style.overflow = 'visible')
    state.clicked ? (body.style.position = 'relative') : (html.style.position = 'relative')
  }, [state.clicked])

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      staggerRevealClose(revealMenu, revealMenuBackground)
      menu.style.style = "none"
      // Set menu to display none
      gsap.to(menu, { duration: 1, css: { display: "none" } })
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      menu.style.style = "display"
      gsap.to(menu, { duration: 0, css: { display: "block" } })
      //Allow menu to have height of 100%
      gsap.to([revealMenuBackground, revealMenu], {
        duration: 0,
        opacity: 1,
        height: "100%",
      })
      staggerReveal(revealMenuBackground, revealMenu)

      staggerText(line1, line2, line3, line4, line5, line6, line7, line8)
    }
  }, [state])
  return (
    <MenuWrapper ref={el => (menu = el)}>
      <SecondaryBackgroundColor ref={el => (revealMenuBackground = el)} />
      <MenuLayer ref={el => (revealMenu = el)}>
        <Container>
          <MenuLinks>
            <Nav>
              <List>
                <Item ref={el => (line1 = el)}>
                  <MLink to="/">Home</MLink>
                </Item>
                <Item ref={el => (line2 = el)}>
                  <MLink to="/about">About</MLink>
                </Item>
                <Item ref={el => (line3 = el)}>
                  <MLink to="/services">Services</MLink>
                </Item>
                <Item ref={el => (line4 = el)}>
                  <MLink to="/results">Results</MLink>
                </Item>
              </List>
            </Nav>
            <Nav>
              <List>

                <Item ref={el => (line5 = el)} id="careersmenu">
                  <MLink to="/careers">Careers</MLink>
                </Item>
                <Item ref={el => (line6 = el)}>
                  <MLink to="/tactics">Tactics</MLink>
                </Item>

                <Item ref={el => (line7 = el)}>
                  <MLink to="/contact">Contact</MLink>
                </Item>

                <Item ref={el => (line8 = el)} id="startprojectmenu">
                <Button width="large" variant="solid" to="/start-a-project">Start A Project</Button>
              </Item>

                <Item ref={el => (line9 = el)} >
                <MLink to=""></MLink>
              </Item>

              </List>
            </Nav>
          </MenuLinks>
        </Container>
      </MenuLayer>
    </MenuWrapper>
  )
}

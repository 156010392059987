import styled from "@emotion/styled"

const layoutBackground = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.colors.black) return "white"
  // If no variant is specified, return the primary variant in our theme
  if (!props.backgroundColor) return props.theme.colors.black

  // Dynamically determine the background variant based on props
  let color
  switch (props.backgroundColor) {
    case "light":
      color = "white"
      break
    case "dark":
      color = props.theme.colors.black
      break
    case "gray":
      color = "#313131"
      break
    default:
      color = props.theme.colors.black
      break
  }

  return color
}

export const LayoutWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1rem repeat(6, 1fr) 1rem;
  grid-template-rows: 5rem auto;
  background-color: ${props => layoutBackground(props)};
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: 1fr repeat(12, minmax(auto, 5.5rem)) 1fr;
    gap: 0 2rem;
  }

  @media ${props => props.theme.breakpoints.extralarge} {
    grid-template-rows: ${props =>
      props.hasHeader ? "43.875rem auto" : "10rem auto"};
  }
`
export const FullWidthContainer = styled.div`
  grid-column: 1 / span 8;
  background: white;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1rem repeat(6, 1fr) 1rem;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    grid-template-columns: 1fr repeat(12, minmax(auto, 5.5rem)) 1fr;
    gap: 0 2rem;
  }
`

export const validateProject = (values, terms) => {
  let errors = {}
  let validateEmailRegex = RegExp(
    /^(([^<>(),;:\s@]+([^<>(),;:\s@]+)*)|(.+))@(([^<>()[,;:\s@]+)+[^<>()[,;:\s@]{2,})$/i
  )
  if (!values.email) {
    errors.email = "Email address is required"
  } else if (!validateEmailRegex.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  if (!values.projectabout) {
    errors.projectabout = "Project description is required"
  } else if (values.projectabout.length < 10) {
    errors.projectabout = "Project description needs to be more than 10 characters"
  }
  if (!values.companyName) {
    errors.companyName = "Company name is required"
  }
  if (!values.name) {
    errors.name = "Full name is required"
  }
  if (!values.terms) {
    errors.terms = "Please accept terms and conditions before submitting."
  }
  return errors
}
export default validateProject

import { graphql, useStaticQuery } from "gatsby"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Dot,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useState } from "react"
import MediaQuery from "react-responsive"
import { AppSlide } from "../components"
import { CarouselWrapper } from "../elements"
import IconNext from "../images/next.svg"
import IconPrev from "../images/previous.svg"

export const AppCarousel = () => {
  const [dimensions, setDimensions] = useState({
    width: 1150,
    height: 500,
    visibleSlides: 1.15,
  })
  const handleMediaQueryChange = matches => {
    if (matches === false) {
      setDimensions({
        width: 320,
        visibleSlides: 1,
      })
    }
  }

  const data = useStaticQuery(graphql`
    query {
      wcMobile: file(relativePath: { eq: "homeslidewc.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 590, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wcDesktop: file(relativePath: { eq: "homeslidewc.png" }) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      champsMobile: file(relativePath: { eq: "homeslidecb.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 590, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      champsDesktop: file(relativePath: { eq: "homeslidecb.png" }) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wc: file(relativePath: { eq: "homeslidewc.png" }) {
        publicURL
      }
      champs: file(relativePath: { eq: "homeslidecb.png" }) {
        publicURL
      }
      tvj: file(relativePath: { eq: "tvj.png" }) {
        publicURL
      }
    }
  `)

  const wcSources = [
    data.wcMobile.childImageSharp.fluid,
    {
      ...data.wcDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const champsSources = [
    data.champsMobile.childImageSharp.fluid,
    {
      ...data.champsDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <>
      <CarouselWrapper>
        <MediaQuery minWidth={764} onChange={handleMediaQueryChange}>
          <CarouselProvider
            naturalSlideWidth={dimensions.width}
            naturalSlideHeight={dimensions.height}
            visibleSlides={dimensions.visibleSlides}
            totalSlides={2}
          >
            <Slider>
              <Slide index={0}>
                <AppSlide 
                image={wcSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Marketing Transformation" 
                event="World Cup 2018"
                description="Find out how we gained 62M in organic impressions for TVJ."
                button={"/results/world-cup-2018"}
                />
              </Slide>
              <Slide index={1}>
                <AppSlide
                image={champsSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Experience Transformation" 
                event="Champsbot 2017"
                description="Find out how we revolutionized the sporting experience with real-time results and points standings."
                button={"/results/champsbot"}
                />
              </Slide>
              {/* <Slide index={2}>
                <AppSlide 
                image={champsSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Experience Transformation" 
                event="Champsbot 2017"
                description="Find out how we revolutionized the sporting experience with real-time results and points standings."
                button={"/results/champsbot"}
                />
              </Slide>
              <Slide index={3}>
                <AppSlide
                image={champsSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Experience Transformation" 
                event="Champsbot 2017"
                description="Find out how we revolutionized the sporting experience with real-time results and points standings."
                button={"/results/champsbot"}
                />
              </Slide> */}
            </Slider>
            <ButtonBack className="buttonBack">
              <IconPrev />
            </ButtonBack>
            <ButtonNext className="buttonNext">
              <IconNext />
            </ButtonNext>
            <DotGroup />
          </CarouselProvider>
        </MediaQuery>
        
        {/* mobile slide */}
        <MediaQuery maxWidth={763}>
          <CarouselProvider
            naturalSlideWidth={320}
            naturalSlideHeight={dimensions.height}
            visibleSlides={1}
            totalSlides={2}
          >
            <Slider>
            <Slide index={0}>
                <AppSlide 
                image={wcSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Marketing Transformation" 
                event="World Cup 2018"
                description="Find out how we gained 62M in organic impressions for TVJ."
                button={"/results/world-cup-2018"}
                />
              </Slide>
              <Slide index={1}>
              <AppSlide
                image={champsSources} 
                logo={data.tvj.publicURL} 
                brand="Television Jamaica" 
                service="Experience Transformation" 
                event="Champsbot 2017"
                description="Find out how we revolutionized the sporting experience with real-time results and points standings."
                button={"/results/champsbot"}
                />
              </Slide>
            </Slider>

            <div className="sliderbox">
            <Dot slide={0} disabled={false} >
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
            </Dot>
            <Dot slide={1} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>    
           </div>

            <ButtonBack className="buttonBack">
              <IconPrev />
            </ButtonBack>
            <ButtonNext className="buttonNext">
              <IconNext />
            </ButtonNext>
          </CarouselProvider>
        </MediaQuery>
      </CarouselWrapper>
    </>
  )
}

import React from "react"
import { Button } from "../components"
import { P, SlideImage, SlideInfoBox, SlideWrapper, AboutSlideWrapper, AboutSlideInfoBox, AboutSlideImage } from "../elements"

export const AppSlide = ({ image, logo, service="Marketing Transformation", event="Event", description="add desction text here", brand, button }) => {
  return (
    <SlideWrapper>
      <SlideInfoBox>
        <img style={{ width: 39 }} src={logo} alt="tvj logo" />
        <P small="small" uppercase black>
          {brand}
        </P>
        <P
          black
          margin="15px 0 0 0"
          style={{ color: "#868686" }}
          uppercase
          weight="bold"
          size="xSmall"
        >
          {service}
        </P>
        <P black style={{ color: "#868686" }} uppercase size="xSmall">
        {event}
        </P>
        <P
          black
          margin="15px 0"
          style={{
            fontFamily: "itc-avant-garde-gothic-pro, sans-serif",
            textAlign: "center",
          }}
        >
          {description}
        </P>
        <Button variant="dark" to={button}>
          See Results
        </Button>
      </SlideInfoBox>
      <SlideImage fluid={image} alt="Slider image" />
    </SlideWrapper>
  )
}

export const AboutSlide = ({ image, logo, description, year }) => {
  return (
    <AboutSlideWrapper>

      <AboutSlideImage
        placeholderStyle={{ backgroundColor: "black" }}
        fluid={image}
        alt="Slider image"
        className="aboutSlideImg"
      />
      <AboutSlideInfoBox>
      {/* <img style={{ width: 299 }} src={logo} alt="logo" /> */}
      <P
        margin="20px 0"
        weight="bold"
        style={{ fontFamily: "itc-avant-garde-gothic-pro" }}
        black
      >
        {year}
      </P>
      <P
        style={{
          textAlign: "center",
        }}
        black
      >
        {description}
      </P>
    </AboutSlideInfoBox>
    </AboutSlideWrapper>
  )
}

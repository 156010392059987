import styled from "@emotion/styled"

export const StyledSocialIcon = styled.a`
  align-items: center;
  background: ${props => props.theme.colors.white};
  display: inline-flex;
  border-radius: 50%;
  margin-top: ${props => (props.marginTop ? props.marginTop : "30px")};
  position: relative;
  height: 38px;
  width: 38px;
  justify-content: center;
  transition: all 0.5s ease;
  .social__icon {
    color: ${props => props.theme.colors.black};
  }
  .filled {
    fill: currentColor;
    stroke: none;
  }
  &:hover {
    background: ${props => props.theme.colors.purple};
    .social__icon {
      color: ${props => props.theme.colors.white};
    }
  }
`

export const StyledSocialIconGrouped = styled.div`
  align-items: center;
  display: inline-flex;
  ${StyledSocialIcon} {
    margin-right: 12px;
  }
`

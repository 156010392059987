import { bool, node, string } from "prop-types"
import React from "react"
import { Footer, Header } from "../components"
import { LayoutWrapper } from "../elements"

export const Layout = ({ children, hasHeader = true, backgroundColor }) => {
  return (
    <LayoutWrapper backgroundColor={backgroundColor} hasHeader={hasHeader}>
      <Header />
      {children}
      <Footer />
    </LayoutWrapper>
  )
}
Layout.propTypes = {
  children: node.isRequired,
  backgroundColor: string,
  hasHeader: bool,
}

Layout.defaultProps = {
  backgroundColor: "dark",
}

import React from "react"
import { PostCategory, Share } from "../components"
import {
  FeaturedImageOverlay,
  FeaturedImageOverlayContentWrapper,
  FeaturedImageWrapper,
  Image,
} from "../elements"

export const FeaturedImage = ({ fluid, categories }) => {
  return (
    <FeaturedImageWrapper>
      <FeaturedImageOverlay>
        <FeaturedImageOverlayContentWrapper>
          {categories && <PostCategory categories={categories} />}
          <Share />
        </FeaturedImageOverlayContentWrapper>
      </FeaturedImageOverlay>
      <Image fluid={fluid} />
    </FeaturedImageWrapper>
  )
}

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { GalleryImage, GalleryWrapper } from "../elements"

export const Gallery = ({ isFullWidth }) => {
  const data = useStaticQuery(graphql`
    query {
      galleryImage: imageSharp(
        fluid: { originalName: { eq: "gallery-image.png" } }
      ) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      alex: imageSharp(fluid: { originalName: { eq: "alex.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      conrad: imageSharp(fluid: { originalName: { eq: "conrad-brit.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      lee: imageSharp(fluid: { originalName: { eq: "greaves-lee.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      lerone: imageSharp(fluid: { originalName: { eq: "lerone.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      gem: imageSharp(fluid: { originalName: { eq: "gem-nico.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const images = [
    {
      source: data.gem.fluid.src,
    },
    {
      source: data.lee.fluid.src,
    },
    {
      source: data.galleryImage.fluid.src,
    },
    {
      source: data.conrad.fluid.src,
    },
    {
      source: data.lerone.fluid.src,
    },
    {
      source: data.alex.fluid.src,
    },
  ]
  const images2 = [
    {
      source: data.gem.fluid,
    },
    {
      source: data.lee.fluid,
    },
    {
      source: data.galleryImage.fluid,
    },
    {
      source: data.conrad.fluid,
    },
    {
      source: data.lerone.fluid,
    },
    {
      source: data.alex.fluid,
    },
  ]
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  // const openLightbox = useCallback(index => {
  //   setCurrentImage(index)
  //   setViewerIsOpen(true)
  // }, [])
  const openLightbox = () => {
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <>
      <GalleryWrapper isFullWidth={isFullWidth}>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.gem.fluid} />
        </div>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.lee.fluid} />
        </div>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.galleryImage.fluid} />
        </div>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.conrad.fluid} />
        </div>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.lerone.fluid} />
        </div>
        <div onClick={openLightbox}>
          <GalleryImage fluid={data.alex.fluid} />
        </div>
      </GalleryWrapper>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map(x => ({
                ...x,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

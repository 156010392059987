import styled from "@emotion/styled"
import { Link } from "gatsby"

export const MenuWrapper = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  display: none;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const SecondaryBackgroundColor = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  width: 100%;
  background-color: ${props => props.theme.colors.purple};
  z-index: -1;
`
export const MenuLayer = styled.div`
  display: flex;
  background-color: #111315;
  position: relative;
  padding-top: 5rem;
  height: 100%;
  overflow: hidden;
  @media ${props => props.theme.breakpoints.medium} {
    align-items: center;
    padding-top: 1.25rem;
  }
`

export const Container = styled.div`
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 310px;
  @media ${props => props.theme.breakpoints.medium} {
    max-width: 900px;
    height: 500px;
  }
`
export const MenuLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
export const MLink = styled(Link)`
  position: relative;
  font-family: ${props => props.theme.fonts.heading};
  color: white;
  z-index: 10;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  transition: 0.3s;
  &:hover {
    -webkit-text-fill-color: white;
  }
  &:after {
    background: ${props => props.theme.colors.purple};
    bottom: 40%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    width: 0;
    z-index: -1;
    transition: width 1s;
  }
  &:hover::after {
    width: 100%;
  }
`

export const Nav = styled.nav`
  width: 100%;
  @media ${props => props.theme.breakpoints.medium} {
    width: 50%;
  }
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`
export const Item = styled.li`
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.5625rem;
  overflow: hidden;
  cursor: pointer;
  height: 68px;
  position: relative;
  @media ${props => props.theme.breakpoints.medium} {
    font-size: 3.375rem;
  }
`

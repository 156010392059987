import React from "react"
import MailChimpForm from "../hooks/MailChimpForm"
import "../stylesheets/subscribebtn.css"
import {
  SubscribeWrapper,
  H1,
  P,
  SubscribeFormWrapper,
  SubscribeButton,
  SubscribeInput,
} from "../elements"
export const Subscribe = () => {

  

  return (
    <SubscribeWrapper className="subwrap2">
      <div className="joinmsg">
        <H1>Join Our Mailing List & Learn More</H1>
        <P>
          Stay up to date with our services, studies, tactics and open positions.
        </P>
      </div>
      <SubscribeFormWrapper>
              <MailChimpForm/>
      </SubscribeFormWrapper>

    </SubscribeWrapper>
  )
}

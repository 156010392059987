import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from 'react'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import MediaQuery from "react-responsive"
import { AboutSlide} from "../components"
import { CarouselWrapper } from "../elements"
import IconNext from "../images/next.svg"
import IconPrev from "../images/previous.svg"

export const AboutCarousel = () => {
  const [dimensions, setDimensions] = useState({
    width: 1150,
    height: 500,
    visibleSlides: 1.15,
  })
  const handleMediaQueryChange = matches => {
    if (matches === false) {
      setDimensions({
        width: 657,
        visibleSlides: 1,
      })
    }
  }


  const data = useStaticQuery(graphql`
    query {
      image1Mobile: file(relativePath: { eq: "launchedmob.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image1Desktop: file(
        relativePath: { eq: "launched.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2Mobile: file(
        relativePath: { eq: "2013-Cohort Start Pogrammemob.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2Desktop: file(
        relativePath: { eq: "2013-Cohort Start Pogramme.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3Mobile: file(
        relativePath: { eq: "agamemob.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3Desktop: file(
        relativePath: { eq: "agame.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4Mobile: file(relativePath: { eq: "2016-Love Moremob.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4Desktop: file(relativePath: { eq: "givelive.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5Mobile: file(
        relativePath: { eq: "2018- Branson Centre Scale Upmob.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5Desktop: file(
        relativePath: { eq: "startup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6Mobile: file(relativePath: { eq: "2018-PM Awardmob.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6Desktop: file(relativePath: { eq: "2018-PM Award.png" }) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7Mobile: file(relativePath: { eq: "2019- DMZmob.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7Desktop: file(relativePath: { eq: "2019- DMZ.png" }) {
        childImageSharp {
          fluid(maxWidth: 1150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      tvj: file(relativePath: { eq: "branson-logo.png" }) {
        publicURL
      }
    }
  `)

  const sources = [
    [
      data.image1Mobile.childImageSharp.fluid,
      {
        ...data.image1Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image2Mobile.childImageSharp.fluid,
      {
        ...data.image2Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image3Mobile.childImageSharp.fluid,
      {
        ...data.image3Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image4Mobile.childImageSharp.fluid,
      {
        ...data.image4Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image5Mobile.childImageSharp.fluid,
      {
        ...data.image5Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image6Mobile.childImageSharp.fluid,
      {
        ...data.image6Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image7Mobile.childImageSharp.fluid,
      {
        ...data.image7Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image7Mobile.childImageSharp.fluid,
      {
        ...data.image7Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image7Mobile.childImageSharp.fluid,
      {
        ...data.image7Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
    [
      data.image7Mobile.childImageSharp.fluid,
      {
        ...data.image7Desktop.childImageSharp.fluid,
        media: `(min-width: 657px)`,
      },
    ],
  ]

  return (
    <CarouselWrapper>
      <MediaQuery minDeviceWidth={1568} onChange={handleMediaQueryChange}>
        <CarouselProvider
          naturalSlideWidth={dimensions.width}
          naturalSlideHeight={(100+(dimensions.height))}
          visibleSlides={dimensions.visibleSlides}
          totalSlides={sources.length-4}
          className="aboutus"
        >
          <Slider>
          <Slide index={0}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            image={sources[2]}
            year="2012"
            description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
          />
          </Slide>
          <Slide index={1}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            // logo={data.tvj.publicURL}
            image={sources[0]}
            year="2013"
            description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
            />
          </Slide>
          <Slide index={2}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            image={sources[3]}
            year="2016"
            description="IPP donated to several schools and projects for students who were performing well in academics and sports."
            />
          </Slide>
          <Slide index={3}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            image={sources[4]}
            year="2018"
            description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
            />
          </Slide>
          <Slide index={4}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            image={sources[5]}
            year="2018"
            description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
            />
          </Slide>
          <Slide index={5}>
          <AboutSlide 
            // logo={data.tvj.publicURL}
            image={sources[9]}
            year="2019"
            description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
            />
          </Slide>           
            {/* {sources.map((source, index) => (
              <Slide index={index}>
                <AboutSlide 
                logo={data.tvj.publicURL} 
                image={source} 
                description="test"
                year="2011"
                />
              </Slide>
            ))} */}
            </Slider>                             
          <ButtonBack className="buttonBack">
            <IconPrev />
          </ButtonBack>
          <ButtonNext className="buttonNext">
            <IconNext />
          </ButtonNext>
          <DotGroup />
        </CarouselProvider>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={1568} minDeviceWidth={1418} onChange={handleMediaQueryChange}>
      <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={(48+80+(dimensions.height))}
        visibleSlides={dimensions.visibleSlides}
        totalSlides={sources.length-4}
        className="aboutus2"
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
                      
        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
    </MediaQuery>

      
      <MediaQuery maxDeviceWidth={1417} minDeviceWidth={1242} onChange={handleMediaQueryChange}>
      <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={(70+60+(dimensions.height))}
        visibleSlides={dimensions.visibleSlides}
        totalSlides={sources.length-4}
        className="aboutus3"
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
                     
        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
    </MediaQuery>

      <MediaQuery maxDeviceWidth={1241} minDeviceWidth={1157} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={(80+60+(dimensions.height))}
      visibleSlides={dimensions.visibleSlides}
      totalSlides={sources.length-4}
      className="aboutus4"
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>              
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>

      <MediaQuery maxDeviceWidth={1156} minDeviceWidth={988} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={(150+55+(dimensions.height))}
      visibleSlides={dimensions.visibleSlides}
      totalSlides={sources.length-4}
      className="aboutus5"
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
                   
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>

        <MediaQuery maxDeviceWidth={987} minDeviceWidth={790} onChange={handleMediaQueryChange}>
        <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={((dimensions.height)+280+60)}
        visibleSlides={dimensions.visibleSlides}
        totalSlides={sources.length-4}
        className="aboutus6"
        >
        <Slider>
        <Slide index={0}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[2]}
          year="2012"
          description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
        />
        </Slide>
        <Slide index={1}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          // logo={data.tvj.publicURL}
          image={sources[0]}
          year="2013"
          description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
          />
        </Slide>
        <Slide index={2}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[3]}
          year="2016"
          description="IPP donated to several schools and projects for students who were performing well in academics and sports."
          />
        </Slide>
        <Slide index={3}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[4]}
          year="2018"
          description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
          />
        </Slide>
        <Slide index={4}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[5]}
          year="2018"
          description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
          />
        </Slide>
        <Slide index={5}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[9]}
          year="2019"
          description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
          />
        </Slide>           
          {/* {sources.map((source, index) => (
            <Slide index={index}>
              <AboutSlide 
              logo={data.tvj.publicURL} 
              image={source} 
              description="test"
              year="2011"
              />
            </Slide>
          ))} */}
          </Slider>
                 
        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
      </MediaQuery>
    
      <MediaQuery maxDeviceWidth={789} minDeviceWidth={658} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+315+70)}
      visibleSlides={dimensions.visibleSlides}
      totalSlides={sources.length-4}
      className="aboutus7"
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
                  
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>




    {/*Mobile responsive slides*/}
      <MediaQuery maxDeviceWidth={657} minDeviceWidth={584} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+430+350)}
      visibleSlides={1}
      totalSlides={sources.length-4}
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
        <div className="sliderbox">
          <Dot slide={0} disabled={false} >
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
          </Dot>
          <Dot slide={1} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>
          <Dot slide={2} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
          <Dot slide={3} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
          <Dot slide={4} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>     
          <Dot slide={5} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>          
       </div>               
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>

      <MediaQuery maxDeviceWidth={583} minDeviceWidth={564} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+490+400)}
      visibleSlides={1}
      totalSlides={sources.length-4}
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
        <div className="sliderbox">
        <Dot slide={0} disabled={false} >
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
        </Dot>
        <Dot slide={1} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>
        <Dot slide={2} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
        <Dot slide={3} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
      </Dot>  
        <Dot slide={4} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>     
        <Dot slide={5} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>          
     </div>   
               
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
    </CarouselProvider>
    </MediaQuery>

      <MediaQuery maxDeviceWidth={563} minDeviceWidth={482} onChange={handleMediaQueryChange}>
        <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={((dimensions.height)+623+450)}
        visibleSlides={1}
        totalSlides={sources.length-4}
        >
        <Slider>
        <Slide index={0}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[2]}
          year="2012"
          description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
        />
        </Slide>
        <Slide index={1}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          // logo={data.tvj.publicURL}
          image={sources[0]}
          year="2013"
          description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
          />
        </Slide>
        <Slide index={2}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[3]}
          year="2016"
          description="IPP donated to several schools and projects for students who were performing well in academics and sports."
          />
        </Slide>
        <Slide index={3}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[4]}
          year="2018"
          description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
          />
        </Slide>
        <Slide index={4}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[5]}
          year="2018"
          description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
          />
        </Slide>
        <Slide index={5}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[9]}
          year="2019"
          description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
          />
        </Slide>           
          {/* {sources.map((source, index) => (
            <Slide index={index}>
              <AboutSlide 
              logo={data.tvj.publicURL} 
              image={source} 
              description="test"
              year="2011"
              />
            </Slide>
          ))} */}
          </Slider>
          <div className="sliderbox">
            <Dot slide={0} disabled={false} >
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
            </Dot>
            <Dot slide={1} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={2} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>  
            <Dot slide={3} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
            <Dot slide={4} disabled={false}>
                <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>     
            <Dot slide={5} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>          
          </div>   
          
        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
      </MediaQuery>


      <MediaQuery maxDeviceWidth={481} minDeviceWidth={440} onChange={handleMediaQueryChange}>
        <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={((dimensions.height)+875+400)}
        visibleSlides={1}
        totalSlides={sources.length-4}
        >
        <Slider>
        <Slide index={0}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[2]}
          year="2012"
          description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
        />
        </Slide>
        <Slide index={1}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          // logo={data.tvj.publicURL}
          image={sources[0]}
          year="2013"
          description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
          />
        </Slide>
        <Slide index={2}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[3]}
          year="2016"
          description="IPP donated to several schools and projects for students who were performing well in academics and sports."
          />
        </Slide>
        <Slide index={3}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[4]}
          year="2018"
          description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
          />
        </Slide>
        <Slide index={4}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[5]}
          year="2018"
          description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
          />
        </Slide>
        <Slide index={5}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[9]}
          year="2019"
          description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
          />
        </Slide>           
          {/* {sources.map((source, index) => (
            <Slide index={index}>
              <AboutSlide 
              logo={data.tvj.publicURL} 
              image={source} 
              description="test"
              year="2011"
              />
            </Slide>
          ))} */}
          </Slider>
   
          <div className="sliderbox">
          <Dot slide={0} disabled={false} >
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
          </Dot>
          <Dot slide={1} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>
          <Dot slide={2} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
          <Dot slide={3} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
          <Dot slide={4} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>     
          <Dot slide={5} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>          
       </div>             

        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={439} minDeviceWidth={410} onChange={handleMediaQueryChange}>
        <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={((dimensions.height)+1075+420)}
        visibleSlides={1}
        totalSlides={sources.length-4}
        >
        <Slider>
        <Slide index={0}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[2]}
          year="2012"
          description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
        />
        </Slide>
        <Slide index={1}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          // logo={data.tvj.publicURL}
          image={sources[0]}
          year="2013"
          description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
          />
        </Slide>
        <Slide index={2}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[3]}
          year="2016"
          description="IPP donated to several schools and projects for students who were performing well in academics and sports."
          />
        </Slide>
        <Slide index={3}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[4]}
          year="2018"
          description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
          />
        </Slide>
        <Slide index={4}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[5]}
          year="2018"
          description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
          />
        </Slide>
        <Slide index={5}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[9]}
          year="2019"
          description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
          />
        </Slide>           
          {/* {sources.map((source, index) => (
            <Slide index={index}>
              <AboutSlide 
              logo={data.tvj.publicURL} 
              image={source} 
              description="test"
              year="2011"
              />
            </Slide>
          ))} */}
          </Slider>
          <div className="sliderbox">
            <Dot slide={0} disabled={false} >
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
            </Dot>
            <Dot slide={1} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={2} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>  
            <Dot slide={3} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
            <Dot slide={4} disabled={false}>
                <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>     
            <Dot slide={5} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>          
        </div>                    
        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
      </MediaQuery>


      <MediaQuery maxDeviceWidth={409} minDeviceWidth={392} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+1075+515)}
      visibleSlides={1}
      totalSlides={sources.length-4}
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
            
        <div className="sliderbox">
          <Dot slide={0} disabled={false} >
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
          </Dot>
          <Dot slide={1} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>
          <Dot slide={2} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
          <Dot slide={3} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
          <Dot slide={4} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>     
          <Dot slide={5} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>          
       </div>           

      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>


      <MediaQuery maxDeviceWidth={391} minDeviceWidth={350} onChange={handleMediaQueryChange}>
        <CarouselProvider
        naturalSlideWidth={dimensions.width}
        naturalSlideHeight={((dimensions.height)+1285+515)}
        visibleSlides={1}
        totalSlides={sources.length-4}
        >
        <Slider>
        <Slide index={0}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[2]}
          year="2012"
          description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
        />
        </Slide>
        <Slide index={1}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          // logo={data.tvj.publicURL}
          image={sources[0]}
          year="2013"
          description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
          />
        </Slide>
        <Slide index={2}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[3]}
          year="2016"
          description="IPP donated to several schools and projects for students who were performing well in academics and sports."
          />
        </Slide>
        <Slide index={3}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[4]}
          year="2018"
          description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
          />
        </Slide>
        <Slide index={4}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[5]}
          year="2018"
          description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
          />
        </Slide>
        <Slide index={5}>
        <AboutSlide 
          // logo={data.tvj.publicURL}
          image={sources[9]}
          year="2019"
          description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
          />
        </Slide>           
          {/* {sources.map((source, index) => (
            <Slide index={index}>
              <AboutSlide 
              logo={data.tvj.publicURL} 
              image={source} 
              description="test"
              year="2011"
              />
            </Slide>
          ))} */}
          </Slider>
 
          <div className="sliderbox">
          <Dot slide={0} disabled={false} >
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
          </Dot>
          <Dot slide={1} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>
          <Dot slide={2} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>  
          <Dot slide={3} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
          <Dot slide={4} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>     
          <Dot slide={5} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
          </Dot>          
       </div>             

        <ButtonBack className="buttonBack">
          <IconPrev />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <IconNext />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={349} minDeviceWidth={329} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+1285+700)}
      visibleSlides={1}
      totalSlides={sources.length-4}
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
        <div className="sliderbox">
        <Dot slide={0} disabled={false} >
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
        </Dot>
        <Dot slide={1} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>
        <Dot slide={2} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
        <Dot slide={3} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
      </Dot>  
        <Dot slide={4} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>     
        <Dot slide={5} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>          
     </div>                 
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>
  
      <MediaQuery maxDeviceWidth={328} minDeviceWidth={200} onChange={handleMediaQueryChange}>
      <CarouselProvider
      naturalSlideWidth={dimensions.width}
      naturalSlideHeight={((dimensions.height)+1285+1150)}
      visibleSlides={1}
      totalSlides={sources.length-4}
      >
      <Slider>
      <Slide index={0}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[2]}
        year="2012"
        description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
      />
      </Slide>
      <Slide index={1}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        // logo={data.tvj.publicURL}
        image={sources[0]}
        year="2013"
        description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."            
        />
      </Slide>
      <Slide index={2}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[3]}
        year="2016"
        description="IPP donated to several schools and projects for students who were performing well in academics and sports."
        />
      </Slide>
      <Slide index={3}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[4]}
        year="2018"
        description="Featured as a high impact million dollar USD business that can scale even faster with Investment"
        />
      </Slide>
      <Slide index={4}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[5]}
        year="2018"
        description="Our Leader, Conrad Mathison, won the Prime Minister Youth Award for Entrepreneurship. He got the award after providing over 80 jobs in the 2016 to interns across the island while overseeing the development of the first active chatbot in Jamaica."
        />
      </Slide>
      <Slide index={5}>
      <AboutSlide 
        // logo={data.tvj.publicURL}
        image={sources[9]}
        year="2019"
        description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to plot a path for North American expansion."
        />
      </Slide>           
        {/* {sources.map((source, index) => (
          <Slide index={index}>
            <AboutSlide 
            logo={data.tvj.publicURL} 
            image={source} 
            description="test"
            year="2011"
            />
          </Slide>
        ))} */}
        </Slider>
        <div className="sliderbox">
        <Dot slide={0} disabled={false} >
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
        </Dot>
        <Dot slide={1} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>
        <Dot slide={2} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>  
        <Dot slide={3} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
      </Dot>  
        <Dot slide={4} disabled={false}>
            <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>     
        <Dot slide={5} disabled={false}>
          <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
        </Dot>          
     </div>                 
      <ButtonBack className="buttonBack">
        <IconPrev />
      </ButtonBack>
      <ButtonNext className="buttonNext">
        <IconNext />
      </ButtonNext>
      <DotGroup />
    </CarouselProvider>
    </MediaQuery>
    </CarouselWrapper>
  )
}


/*

        <Dot slide={0}>
        <span aria-hidden="true">&middot;</span>
        <span className="sr-only">Carousel Slide 1</span>
      </Dot>
      <Dot slide={1}>
        <span aria-hidden="true">&middot;</span>
        <span className="sr-only">Carousel Slide 2</span>
      </Dot>
      <Dot slide={2}>
        <span aria-hidden="true">&middot;</span>
        <span className="sr-only">Carousel Slide 3</span>
      </Dot>
/*
/*
  <Slide index={6}>
              <AboutSlide 
                // logo={data.tvj.publicURL}
                image={sources[6]}
                year="2017"
                description="Launched Champsbot - a revolutionary sporting experience…"
                />
              </Slide>
              <Slide index={7}>
              <AboutSlide 
                // logo={data.tvj.publicURL}
                image={sources[7]}
                year="2018"
                description="We were the recipient of the Development Bank of Jamaica Ignite Grant to further invest in data and artificial intelligence based on the success of Champsbot and other AI projects."
                />
              </Slide>
              <Slide index={8}>
              <AboutSlide 
                // logo={data.tvj.publicURL}
                image={sources[8]}
                year="2018"
                description="Accepted in Branson Centre Scale up Programme prepared for investment and scaling the business regionally."
                />
              </Slide>
*/


/*
    <div className="sliderbox">
                <Dot slide={0} disabled={false} >
                <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
                </Dot>
                <Dot slide={1} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
                </Dot>
                <Dot slide={2} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
                </Dot>
                <Dot slide={3} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
                </Dot>
                <Dot slide={4} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
                </Dot>
                <Dot slide={5} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
                </Dot>
                <Dot slide={6} disabled={false}>
                  <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
                </Dot>                           
              </div>


*/


/*

<DotGroup showAsSelectedForCurrentSlideOnly={true} className="sliderdot"/>
  <Dot slide={0} disabled={false} >
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
            </Dot>
            <Dot slide={1} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={2} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true} ></button>
            </Dot>
            <Dot slide={3} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={4} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={5} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>
            <Dot slide={6} disabled={false}>
              <button aria-hidden="true" className="sliderdot" showAsSelectedForCurrentSlideOnly={true}></button>
            </Dot>             
              
              

*/

/*

        <Slider>
          <Slide index={0}>
              <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[0]}
              year="2011"
              description="IPP launched as a regional boutique event coverage and social media marketing company based on the growing demand for social media and content services. We were a mighty team of 4 operating from Jamaica and Trindad.
              "
              />
            </Slide>
            <Slide index={1}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[1]}
              year="2012"
              description="We grew our client base to 17 SMEs. Our mission was to bring Jamaican small businesses online."
              />
            </Slide>
            <Slide index={2}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[2]}
              year="2012"
              description="Started our A-Game Internship and IPP University Programme to expose and train university students in the field of digital marketing and content creation to drive out the talent pool."
              />
            </Slide>
            <Slide index={3}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[3]}
              year="2013"
              description="The IPP “Give where you Live” initiative was launched to strengthen our relationship with the surrounding communities. As a way of living out our brand promise of being a “great citizen to the community."
              />
            </Slide>
            <Slide index={4}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[4]}
              year="2014"
              description="Enrolled in the Branson Centre of Entrepreneurship Start Up Programme which helped us to change our business model  to attract new partners like OGM integrated and regional clients like RJR Group."
              />
            </Slide>
            <Slide index={5}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[5]}
              year="2016"
              description="Based on the pains of our new customer base,IPP reinvented itself to capitalize on the disruptions of technology.One of the major changes happen when we realize that the way businesses communicate will be disrupted by AI. As a result, we started investing in artificial intelligence research."
              />
            </Slide>
            <Slide index={9}>
            <AboutSlide 
              // logo={data.tvj.publicURL}
              image={sources[9]}
              year="2019"
              description="Accepted into #1 Tech incubator in the world, DMZ XXY , Accelerator programme to ...plot path for North American expansion."
              />
            </Slide>

          </Slider>
*/
import React from "react"

import {
  CardTitle,
  CardWrapper,
  CardContentWrapper,
  CardTitleWrapper,
  H1,
  P,
} from "../elements"

export const Card = ({
  margin,
  grid = "none",
  black = false,
  children,
  name,
  title,
  description,
}) => {
  return (
    <CardWrapper margin={margin}>
      <CardTitleWrapper>
        <div style={{ maxWidth: 128 }}>
          <CardTitle black={black}>{name}</CardTitle>
        </div>
      </CardTitleWrapper>
      <CardContentWrapper grid={grid}>
        {title && (
          <H1 grid={grid} black={black}>
            {title}
          </H1>
        )}
        {description && <P black={black}>{description}</P>}
        {children}
      </CardContentWrapper>
    </CardWrapper>
  )
}

import React from "react"
import { SocialIconGrouped } from "../components"
import { FooterContentWrapper, FooterWrapper, H1, P, Strike } from "../elements"

export const Footer = () => {
  const icons = [
    { name: "instagram", href: "https://instagram.com/itspixelperfect" },
    { name: "youtube", href: "https://www.youtube.com/user/ItsPixelPerfect" },
    {
      name: "linkedin",
      href: "https://www.linkedin.com/company/it's-pixel-perfect/",
    },
    { name: "facebook", href: "https://www.facebook.com/itspixelperfect/" },
    { name: "twitter", href: "https://twitter.com/itspixelperfect" },
  ]
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <H1  large>
          {/* Huddle up! <br />
          Say Hi <br /> */}
          Let’s work together. <br />
          Send us a message at<br />
          <a href="mailto:hello@itspixelperfect.com"><Strike className="helloIPP" large>hello@itspixelperfect.com</Strike></a>
        </H1>
        <SocialIconGrouped icons={icons} />
        <P margin="26px 0 0 0">
          Suites 22 & 23, <br />
          Technology Innovation Centre, <br />
          University of Technology <br />
          237 Old Hope Road, <br />
          Kingston 6, Jamaica W.I.
        </P>
        <P margin="20px 0 0 0" size="xSmall">
          © It’s Pixel Perfect · All rights reserved 2020 {/*· VAT ID 000000000000*/}
        </P>
      </FooterContentWrapper>
    </FooterWrapper>
  )
}

import axios from "axios"
import { useEffect, useState } from "react"

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    aboutUs: "",
    terms:false,
  })
  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState({})

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const handleChange = event => {
    const { name, value, checked } = event.target
    setValues({
      ...values,
      [name]: value,
    })

    setTerms(checked)
   // setErrors(validateProject(values))
 //   console.log(errors)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    setServerState({
      submitting: true,
      status: null,
    })

    console.log('Checking validity')
    console.log(values)
    console.log(errors)
    if (Object.keys(errors).length === 0 ) {
      console.log('SSSSSSSubmitted')
      console.log('SSSSSent')
      axios
        .post(
          "https://getform.io/f/ed6c229c-e76e-4841-8944-a63234fbb500",
          values,
          {
            headers: { Accept: "application/json" },
            data: new FormData(form),
          }
        )
        .then(r => {
          handleServerResponse(true, "Thanks!", form)
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form)
        })
    }
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && serverState.submitting) {
      setServerState({
        submitting: false,
        status: null,
      })
      callback()
    }
  }, [errors])

  
  useEffect(() => {
    console.log(values)
    setErrors(validate(values))
   callback();
}, [values])

  return { handleChange, handleSubmit, values, errors, terms }
}
export default useForm

import styled from "@emotion/styled"
import Img from "gatsby-image"

export const CTAWrapper = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.purple};
  display: flex;
  grid-column: 1 / span 8;
  overflow: hidden;
  position: relative;
  justify-content: end;
  @media ${props => props.theme.breakpoints.medium} {
    height: 500px;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
  }
`
export const CTACard = styled.div`
  background: white;
  margin: auto;
  padding: 25px;
  left: 0;
  position: absolute;
  right: 0;
  width: 310px;
  z-index: 1;
  h1 {
    font-size: 20px;
  }
  p {
    // margin: 20px 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding: 50px;
    width: 448px;
    left: auto;
    h1 {
      font-size: 35px;
    }
    p {
      // margin: 20px 0 28px 0;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    right: 60px;
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    right: 379px;
  }
`
export const CTAImage = styled(Img)`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`


export const CTAWrapper2 = styled.div`
   grid-column: 1 / span 14;
   display:flex;
   background-color:white;
`
export const CTACard2 = styled.div`
  background: white;
  margin: auto;
  padding: 30px;
  left: 0;
  width:40%;
`
export const CTAImage2 = styled(Img)`
  width:60%;
  height:600px;
`
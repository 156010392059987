import styled from "@emotion/styled"
import Img from "gatsby-image"

export const HeroWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray1};
  grid-column: 1 / span 8;
  grid-row: 1 / 3;
  position: relative;
  display: flex;
  height: 702px;
  align-items: center;
  flex-direction: column;
  video {
    display: none;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    height: ${props => (props.hasBgVideo ? "0" : "702px")};
    padding-top: ${props => (props.hasBgVideo ? "calc(100% / (16 / 9))" : "0")};
    video {
      display: ${props => (props.hasBgVideo ? "block" : "none")};
    }
  }
`

export const HeroImage = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`

export const HeroOverlay = styled.div`
  display: grid;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  align-items: center;
  grid-template-columns: 1rem repeat(6, 1fr) 1rem;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    grid-template-columns: 1fr repeat(12, minmax(auto, 5.5rem)) 1fr;
    gap: 0 2rem;
  }
  z-index: 3;
  position: absolute;
  background-color: ${props =>
    props.hasOverlay ? "rgba(0, 0, 0, 0.5)" : "none"};
`

export const HeroContentWrapper = styled.div`
  grid-column: 2 / span 6;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.breakpoints.medium} {
    grid-column: 2 / span 6;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 4 / span 6;
  }
`

import styled from "@emotion/styled"
import Img from "gatsby-image"

export const GalleryWrapper = styled.div`
  background-color: ${props => (props.isFullWidth ? "none" : "white")};
  grid-column: 1 / span 8;
  display: grid;
  gap: ${props => (props.isFullWidth ? "36px" : "8px")};
  grid-template-columns: repeat(1, minmax(360px, 1fr));
  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: repeat(2, minmax(360px, 1fr));
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    grid-template-columns: ${props =>
      props.isFullWidth
        ? "repeat(2, minmax(360px, 1fr))"
        : "repeat(3, minmax(360px, 1fr))"};
  }
`

export const GalleryImage = styled(Img)`
  width: 100%;
  height: 100%;
`

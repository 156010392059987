import React from "react"
import { Button } from "../components"
import {
  H1,
  P,
  TacticsCardDetails,
  TacticsCardImage,
  TacticsCardWrapper,
  TopTacticsCardCTAWrapper,
  TopTacticsCardImage,
  TopTacticsCardImageOverlay,
  TopTacticsCardWrapper,
  ToTacticsCardInfo,
} from "../elements"
import IconArticle from "../images/icon-book.svg"
import IconCalendar from "../images/icon-calendar.svg"
import IconUser from "../images/icon-user.svg"
import { PostCategory } from "./PostCategory"

const Icons = {
  article: IconArticle,
  user: IconUser,
  calendar: IconCalendar,
}

export const TopTacticsCard = ({ tactic }) => {
  return (
    <TopTacticsCardWrapper>
      <PostCategory
        className="top-tactics__category"
        categories={tactic.categories}
      />
      <TopTacticsCardCTAWrapper to={`/tactics/${tactic.slug}`}>
        <ToTacticsCardInfo>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: 30,
            }}
          >
            <PostIcon name="user" />
            <P black uppercase size="xSmall" weight="bold">
              {tactic.author.name}
            </P>
          </div>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: 30,
            }}
          >
            <PostIcon name="calendar" />
            <P black uppercase size="xSmall" weight="bold">
              {tactic.date}
            </P>
          </div>
        </ToTacticsCardInfo>
        <H1 margin="20px 0 0 0" black>
          {tactic.title}
        </H1>

        <Button variant="dark" icon="article" className="tacticsbtn" to={`/tactics/${tactic.slug}`}>
          Check It Out
        </Button>
      </TopTacticsCardCTAWrapper>
      <div style={{ height: 430, position: "relative" }}>
        <TopTacticsCardImageOverlay />
        <TopTacticsCardImage
          fluid={tactic.featured_media.localFile.childImageSharp.fluid}
        />
      </div>
    </TopTacticsCardWrapper>
  )
}

const PostIcon = ({ name }) => {
  if (Icons[name] === undefined) return null
  const Icon = Icons[name]
  return (
    <span style={{ marginRight: 4 }}>
      <Icon />
    </span>
  )
}

export const TacticsCard = ({ tactic }) => {
  return (
    <TacticsCardWrapper to={`/tactics/${tactic.slug}`}>
      <div style={{ height: 215, position: "relative" }}>
        <PostCategory
          className="tactics__category"
          categories={tactic.categories}
        />
        <TacticsCardImage
          fluid={tactic.featured_media.localFile.childImageSharp.fluid}
        />
      </div>

      <TacticsCardDetails>
        <ToTacticsCardInfo>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <PostIcon name="user" />
            <P black uppercase size="xSmall" weight="bold">
              {tactic.author.name}
            </P>
          </div>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <PostIcon name="calendar" />
            <P black uppercase size="xSmall" weight="bold">
              {tactic.date}
            </P>
          </div>
        </ToTacticsCardInfo>

        <H1
          black
          margin="25px 0"
          style={{ fontSize: "1.125rem", lineHeight: "22px" }}
        >
          {tactic.title}
        </H1>
        <Button variant="dark" icon="article" to={`/tactics/${tactic.slug}`}>
          Check It Out
        </Button>
      </TacticsCardDetails>
    </TacticsCardWrapper>
  )
}

import styled from "@emotion/styled"

export const ScoreCardWrapper = styled.div`
  display: block;
  // width: 320px;
  width: 100%;
`

export const ScoreCardNumber = styled.p`
  //font-size: 6.5625rem;
  font-size: 4rem;
  font-family: ${props => props.theme.fonts.heading};
`

import styled from "@emotion/styled"

export const LogoWrapper = styled.div`
  display: grid;
  gap: 0 42px;
  align-items: center;
  grid-template-columns: repeat(2, minmax(auto, 116px));
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(4, minmax(auto, 116px));
  }
`

export const LogoItem = styled.div`
  img {
    width: 100%;
    height: 100%;
    transition: all 0.27s ease-out;
    filter: grayscale(100%);
    &:hover
    {
      filter: grayscale(0%);
    }

  }
`

/*    filter: url("data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'><filter%20id='grayscale'><feColorMatrix%20type='matrix'%20values='0.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200%200%200%201%200'/></filter></svg>#grayscale");
    &:hover {
      filter: none;
    }*/
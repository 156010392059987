import React from "react"

import {
  AchivementCardWrapper,
  AchivementCardDetailsWrapper,
  P,
  H1,
} from "../elements"

export const AchievementCard = ({ achievement }) => {
  const { year, name, description } = achievement
  return (
    <AchivementCardWrapper>
      <P size="large">{year}</P>
      <AchivementCardDetailsWrapper>
        <H1>{name}</H1>
        <P>{description}</P>
      </AchivementCardDetailsWrapper>
    </AchivementCardWrapper>
  )
}

import styled from "@emotion/styled"

export const AchivementCardWrapper = styled.li`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px #626262 solid;
`
export const AchivementCardDetailsWrapper = styled.div`
  display: block;
  margin-left: 1rem;
`

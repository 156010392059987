import styled from "@emotion/styled"

export const SubscribeWrapper = styled.div`
  background: ${props => props.theme.colors.purple};
  height: 300px;
  padding: 45px 25px;
  align-items: center;
  gap: 2rem;
  grid-column: 1 / span 8;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  @media ${props => props.theme.breakpoints.large} {
    gap: 3rem;
    grid-column: 1 / span 14;
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    padding: 50px 140px;
    height: 10.3125rem;
    grid-template-columns: repeat(auto-fit, minmax(596px, 1fr));
  }
`
export const SubscribeFormWrapper = styled.div`
  display: inline-flex;
`
export const SubscribeInput = styled.input`
  color: white;
  background: none;
  border: 2px solid white;
  padding: 25px 0 25px 25px;
  width: 168px;
  &::placeholder {
    color: white;
    opacity: 1;
  }
  @media ${props => props.theme.breakpoints.large} {
    width: 294px;
  }
`
export const SubscribeButton = styled.button`
  background: white;
  width: 142px;
  cursor: pointer;
  padding: 25px 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.button};
  color: ${props => props.theme.colors.purple};
`

import styled from "@emotion/styled"
import { Link } from "gatsby"

export const PositionWrapperLinked = styled(Link)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px #626262 solid;
  padding-bottom: 1rem;
  &:hover {
    h2 {
      color: ${props => props.theme.colors.purple};
      transition: all 0.3s ease-out;
    }
  }
`
export const PositionWrapper = PositionWrapperLinked.withComponent("div")

export const PositionDetailsWrapper = styled.div`
  display: grid;
  margin-top: 14px;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem 0;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
`

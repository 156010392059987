import styled from "@emotion/styled"
import Img from "gatsby-image"

export const CareersVideoWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray1};
  grid-column: 1 / span 8;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
  }
`
export const CareersVideoCardWrapper = styled.div`
  height: 550px;
  position: relative;
`
export const CareersVideoCardThumb = styled(Img)`
  width: 100%;
  height: 100%;
  position:top !important;
  object-fit: cover !important;
  object-position: center center !important;
  z-index: 1;
  top: 0;
  left: 0;
`
export const CareersVideoCardOverlay = styled.div`
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
`
export const CareersVideosContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
`
export const CareersNamePositionWrapper = styled.div`
  margin-bottom: 50px;
  align-self: end;
  position: absolute;
  bottom: 0;
  p {
    text-align: center;
  }
  right: 0;
  left: 0;
  @media ${props => props.theme.breakpoints.medium} {
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    margin: 0 50px 50px 0;
    p {
      text-align: start;
    }
  }
`

import styled from "@emotion/styled"

export const HeaderWrapper = styled.header`
  display: inline-flex;
  justify-content: space-between;
  grid-column: 2 / span 6;
  grid-row: 1 / 2;
  position: relative;
  z-index: 20;
  @media ${props => props.theme.breakpoints.medium} {
    grid-column: 2 / span 6;
    padding-top: 3rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 2 / span 12;
    height: 16rem;
    z-index: auto;
  }
`
export const LogoMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  @media ${props => props.theme.breakpoints.large} {
    width: auto;
    flex-direction: column;
    height: 100%;
  }
`
export const MenuButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    margin-left: 10px;
  }
  button {
    div {
      height: 0.22em;
    }
  }
`

import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { useEffect, useState } from "react"

import {
    SubscribeWrapper,
    H1,
    P,
    SubscribeFormWrapper,
    SubscribeButton,
    SubscribeInput,
  } from "../elements"

export default class IndexPage extends React.Component {
    state = {
        name: null,
        email: null,
    }

    _handleChange = e => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        })
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }
/*
    state = {
        submit: false,
      }
    
      _handleChange = e => {
        this.setState({
          [`${e.target.name}`]: e.target.value,
        })
      }
*/
    _handleSubmit = e => {
        e.preventDefault()

//This is for Mailchimp
        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`)

                if (result !== 'success') {
                    throw msg
                }
                alert(msg)
            })
            .catch(err => {
                console.log('err', err)
                alert('You have already subscribed')
            })
/*
        const data = new FormData(e.target);
        console.log(data);
        fetch('https://itspixelperfect.activehosted.com/proc.php', {
        method: 'POST',
        body: data,
        mode: 'no-cors',
        })
        .then(response => {
        this.setState({ submit: true });

        setTimeout(() => {
            this.setState({ submit: false });
            }, 2000);

        if (this.state.submit !== true) {
            alert('Email Subscription Was unsuccessful')
        }
        else{
            alert('Please check your email to confirm your subscription');
        }
        console.log(response)
        })
        .catch(err => {
        console.log('err', err);
        alert(err);
        });
*/
    }

    render() {
        return (
                <form onSubmit={this._handleSubmit}>
                <SubscribeInput 
                    type="email"
                    onChange={this._handleChange}
                    placeholder="Email Address"
                    name="email"
                />                 
                <SubscribeButton id="subsc" type="submit">Subscribe Now</SubscribeButton>
            </form>
        )
    }
}
import React from "react"
import { SocialIconGrouped } from "../components"
import { P } from "../elements"

export const Share = () => {


  const url = typeof window !== 'undefined' ? window.location.href : '';
  
  const icons = [
    { name: "facebook", href: "https://www.facebook.com/sharer/sharer.php?u="+url },
    { name: "twitter", href: "https://twitter.com/intent/tweet?url="+url },
  ]

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <P margin="0 10px 0 0" uppercase size="xSmall">
        Share with
      </P>
      <SocialIconGrouped marginTop="0" icons={icons} />
    </div>
  )
}

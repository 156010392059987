import React from "react"
import {
  H1,
  H2,
  P,
  PositionDetailsWrapper,
  PositionWrapper,
  PositionWrapperLinked,
} from "../elements"
import {Link} from "gatsby"
export const PositionCard = ({
  hideH2,
  title,
  to,
  Button,
  employmentType,
  location,
  dueDate,
}) => {
  if (to) {
    return (
      <PositionWrapperLinked to={to} className="positonWrap">
        {hideH2 && hideH2 ? (
          <H1 large className="posname">{title}</H1>
        ) : (
          <H2 style={{ color: "#B5B5B5", fontSize: "1.425rem"}} hideH2={hideH2}>{title}</H2>
        )}
        <PositionDetailsWrapper >
          <div>
            <P margin="0 0 8px 0" weight="bold">
              Location
            </P>
            <P>{location}</P>
          </div>
          <div>
            <P margin="0 0 8px 0" weight="bold">
              Application Due Date
            </P>
            <P>{dueDate}</P>
          </div>
          <div>
            <P margin="0 0 8px 0" weight="bold">
              Type of Employment
            </P>
            <P>{employmentType}</P>
          </div>
        </PositionDetailsWrapper>
      </PositionWrapperLinked>
    )
  }
  return (
    <PositionWrapper>
      {hideH2 && hideH2 ? (
        <div style={{ zIndex:"2"}}  >
        <Link to="/careers"   ><P margin="0 0 20px 0" >&#8249; Go Back</P></Link>
        <H1 large className="posname">{title}</H1>
        </div>

      ) : (
        <H2 style={{ color: "#B5B5B5", fontSize: "1.425rem"}} hideH2={hideH2}>{title}</H2>
      )}
      <PositionDetailsWrapper>
        <div>
          <P margin="0 0 8px 0" weight="bold">
            Location
          </P>
          <P>{location}</P>
        </div>
        <div>
          <P margin="0 0 8px 0" weight="bold">
            Application Due Date
          </P>
          <P>{dueDate}</P>
        </div>
        <div>
          <P margin="0 0 8px 0" weight="bold">
            Type of Employment
          </P>
          <P>{employmentType}</P>
        </div>
      </PositionDetailsWrapper>
    </PositionWrapper>
  )
}

//      <Button  to={to} variant="solid" width="large" margin="50px 0">View details & apply</Button>
import styled from "@emotion/styled"
import { Link } from "gatsby"

export const ResultCardWrapper = styled(Link)`
  width: 100%;
  display: block;
`

export const ResultCardImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  .result-image {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  &:hover {
    .result-image {
      transform: scale(1.5);
    }
  }
`

export const ResultCardDetailsWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
`
export const ResultCardGrid = styled.div`
  display: grid;
  margin: 100px 0;
  gap: 50px 0;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 1fr 1fr;
    gap: 50px 48px;
  }
`

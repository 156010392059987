import React from "react"
import { PostCategoryWrapper } from "../elements"
import IconArticle from "../images/icon-book.svg"

const Icons = {
  article: IconArticle,
}

export const PostCategory = ({ categories, className }) => {
  return (
    <PostCategoryWrapper className={className}>
      <PostIcon name="article" />
      {categories.map(category => category.name)}
    </PostCategoryWrapper>
  )
}

const PostIcon = ({ name, className }) => {
  if (Icons[name] === undefined) return null
  const Icon = Icons[name]
  return (
    <span style={{ marginRight: 4, display: "flex", alignItems: "center" }}>
      <Icon className="icon" />
    </span>
  )
}

export const validate = (values, terms) => {
  let errors = {}
  let validateEmailRegex = RegExp(
    /^(([^<>(),;:\s@]+([^<>(),;:\s@]+)*)|(.+))@(([^<>()[,;:\s@]+)+[^<>()[,;:\s@]{2,})$/i
  )
  if (!values.email) {
    errors.email = "Email address is required"
  } else if (!validateEmailRegex.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  if (!values.message) {
    errors.message = "Message is required"
  } else if (values.message.length < 10) {
    errors.message = "Message needs to be more than 10 characters"
  }
  if (!values.name) {
    errors.name = "Full name is required"
  }
  if (!values.terms) {
    errors.terms = "Please accept terms and conditions before submitting."
  }
  return errors
}
export default validate

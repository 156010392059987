import styled from "@emotion/styled"
import Img from "gatsby-image"

export const VideoWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray1};
  grid-column: 1 / span 8;
  position: relative;
  display: flex;
  height: 767px;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    height: 767px;
  }
`
export const VideoThumb = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`
export const VideoOverlay = styled.div`
  display: flex;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
`

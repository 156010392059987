import styled from "@emotion/styled"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React from "react"

export const FormWrapper = styled.form`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 32px 100px;

  .full-width {
    @media ${props => props.theme.breakpoints.medium} {
      grid-column: 1 / 3;
    }
  }
  .text-field {
    label {
      color: black;
    }
    div {
      color:   black;

      &:before {
        border-bottom: 1px solid   black;
      }
      &:hover:before {
        border-bottom: 2px solid   black;
      }
      &:after {
        border-bottom: 2px solid ${props => props.theme.colors.purple};
      }
    }
  }
  .submit-button {
    max-width: 100%;
    @media ${props => props.theme.breakpoints.medium} {
      grid-column: 1 / 3;
    }
  }
`
export const IPPTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5800FF",
    },
    "& .MuiInput-underline": {
      "&:hover MuiInput-underline:after": {
        borderBottomColor: "white",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5800FF",
      },
    },
  },
})(TextField)

export const IPPCheckbox = withStyles({
  root: {
    color: "#5800FF",
    "&$checked": {
      color: "#5800FF",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

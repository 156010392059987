import styled from "@emotion/styled"

export const ContentWrapper = styled.div`
  grid-column: 2 / span 6;
  margin: ${props => (props.spacing ? "100px 0 31px 0" : "50px 0 100px 0")};
  display: flex;
  flex-direction: column;
  grid-template-columns: 1rem repeat(6, 1fr) 1rem;
  @media ${props => props.theme.breakpoints.medium} {
    grid-column: 2 / span 6;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 4 / span 8;
  }
`

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import "../../node_modules/react-modal-video/scss/modal-video.scss"
import { Button } from "../components"
import { VideoOverlay, VideoThumb, VideoWrapper } from "../elements"

export const Video = ({ thumb, buttonTitle = "Play Video", videoId }) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fluid: { originalName: { eq: "mel-thumb.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
  }
  return (
    <VideoWrapper>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
      <VideoOverlay>
        <Button onClick={openModal} icon="play">
          {buttonTitle}
        </Button>
      </VideoOverlay>
      <VideoThumb fluid={thumb ? thumb : data.imageSharp.fluid} />
    </VideoWrapper>
  )
}

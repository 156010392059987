/**
 * Use this link to learn how to implement the follow:https://dimitr.im/optimize-loading-images-wordpress-gatsby
 *
 */
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

export const ArticleImage = ({ src, alt, width }) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpMedia {
        edges {
          node {
            source_url
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const originalSource = src.replace(
    /^(http?s:\/\/.+?\/.+?)-(\d+x\d+)\.(.+?)$/g,
    "$1.$3"
  )

  const image = data.allWordpressWpMedia.edges.find(
    ({ node }) => node.source_url === originalSource
  )
  console.log(image)
  return image == null || image.node.localFile.childImageSharp == null ? (
    <img
      src={src}
      alt={alt}
      style={{ width: width ? width : "100%", margin: "30px 0" }}
    />
  ) : (
    <Img
      fluid={image.node.localFile.childImageSharp.fluid}
      alt={alt}
      style={{
        width: width ? width + "px" : "100%",
        maxWidth: "100%",
        margin: "30px 0",
      }}
    />
  )
}

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { LogoItem, LogoWrapper } from "../elements"
export const LogoBox = () => {
  const data = useStaticQuery(graphql`
    query {
      rjr: file(relativePath: { eq: "rjrpng.png" }) {
        publicURL
      }
      gk: file(relativePath: { eq: "gk.svg" }) {
        publicURL
      }
      norbrook: file(relativePath: { eq: "norpg.png" }) {
        publicURL
      }
      hl: file(relativePath: { eq: "hlpng.png" }) {
        publicURL
      }
      atl: file(relativePath: { eq: "atlpg.png" }) {
        publicURL
      }
      salada: file(relativePath: { eq: "salpng.png" }) {
        publicURL
      }
      ral: file(relativePath: { eq: "ral.png" }) {
        publicURL
      }
      wisynco: file(relativePath: { eq: "wisynco.svg" }) {
        publicURL
      }
    } 
  `)
  return (
    <LogoWrapper className="logobx">
      <LogoItem>
        <img src={data.rjr.publicURL} alt="RJR logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.gk.publicURL} alt="Grace Kennedy logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.atl.publicURL} alt="ATL logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.hl.publicURL} alt="Hardware & Lumber logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.ral.publicURL} alt="RAL logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.salada.publicURL} alt="Salada logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.norbrook.publicURL} alt="Norbrook logo" />
      </LogoItem>
      <LogoItem>
        <img src={data.wisynco.publicURL} alt="Wisynco logo" />
      </LogoItem>
    </LogoWrapper>
  )
}

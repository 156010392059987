import { func, node, string } from "prop-types"
import React from "react"
import {
  StyledButton,
  StyledExternalLinkButton,
  StyledLinkButton,
} from "../elements"
import IconArticle from "../images/icon-article.svg"
import IconPlay from "../images/icon-play.svg"

const Icons = {
  play: IconPlay,
  article: IconArticle,
}

export const Button = ({
  children,
  to,
  className,
  href,
  onClick,
  icon,
  variant,
  width,
  margin,
}) => {
  if (to)
    return (
      <StyledLinkButton
        width={width}
        className={className}
        margin={margin}
        name={icon}
        variant={variant}
        to={to}
      >
        <div className="button__children">
          {icon && <ButtonIcon name={icon} />}
          <p>{children}</p>
        </div>
      </StyledLinkButton>
    )
  if (href) {
    return (
      <StyledExternalLinkButton
        name={icon}
        width={width}
        className={className}
        margin={margin}
        variant={variant}
        href={href}
        target="_blank"
      >
        <div className="button__children">
          {icon && <ButtonIcon name={icon} />}
          <p>{children}</p>
        </div>
      </StyledExternalLinkButton>
    )
  }

  return (
    <StyledButton
      onClick={onClick}
      width={width}
      margin={margin}
      className={className}
      name={icon}
      variant={variant}
    >
      <div className="button__children">
        {icon && <ButtonIcon name={icon} />}
        <p>{children}</p>
      </div>
    </StyledButton>
  )
}

Button.propTypes = {
  children: node.isRequired,
  to: string,
  href: string,
  icon: string,
  onClick: func,
  variant: string,
  width: string,
}

Button.defaultProps = {
  variant: "light",
  to: "",
  href: "",
  width: "",
}

const ButtonIcon = ({ name }) => {
  // If icon name value doesn't match Icons object keys then return null
  if (Icons[name] === undefined) return null
  // If icon found, return the icon in a span element
  const Icon = Icons[name]
  return (
    <span className="button__icon">
      <Icon />
    </span>
  )
}

ButtonIcon.propTypes = {
  name: string.isRequired,
}

import styled from "@emotion/styled"

export const ServiceCardWrapper = styled.div`
  width: 100%;
  @media ${props => props.theme.breakpoints.extralarge} {
    display: flex;
  }
`

export const ServiceCardImageWrapper = styled.div`
  @media ${props => props.theme.breakpoints.extralarge} {
    width: 50%;
  }
`

export const ServiceCardContentWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: spac-between;
  @media ${props => props.theme.breakpoints.extralarge} {
    width: 50%;
    margin: 0;
    margin-left: 3.125rem;
  }
`
export const ServiceCardTitleWrapper = styled.div`
  position: absolute;
  margin-top: -70px;
  z-index: 1;
  p {
    font-size: 1.54rem;
    margin-bottom: 8px;
  }
  p::before {
    content: "/ ";
    font-weight: bold;
  }
  p::after {
    content: " / ${props => (props.number ? props.number : "01")}";
    font-weight: 900;
  }
  @media ${props => props.theme.breakpoints.medium} {
    margin-top: -120px;
  }
`
export const ServiceList = styled.div`
  display: grid;
  margin-top: 150px;
  gap: 10rem 0;
  @media ${props => props.theme.breakpoints.medium} {
    margin-top: 200px;
  }
`

import styled from "@emotion/styled"
import Img from "gatsby-image"

export const FeaturedImageWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray1};
  grid-column: 1 / span 8;
  grid-row: 1 / 3;
  position: relative;
  display: flex;
  height: 350px;
  @media ${props => props.theme.breakpoints.medium} {
    height: 520px;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
  }
`
export const Image = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`
export const FeaturedImageOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
`
export const FeaturedImageOverlayContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  bottom: 0;
  left: 20px;
  padding-bottom: 25px;
  position: absolute;
  justify-content: space-between;
  @media ${props => props.theme.breakpoints.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 55%;
  }
`

export const ArticleImageWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 20px 0;
  background-color: ${props => props.theme.colors.gray1};
  padding-top: calc((75 / 116) * 100%);
`

import parse, { domToReact } from "html-react-parser"
import React from "react"
import { ArticleImage } from "../components"
import { H2, H3, P } from "../elements"

const postConfig = {
  replace: ({ attribs, name, children }) => {
    if (name && name === "p") {
      return (
        <div className="resultsp">
        <P style={{ fontSize: "1.25rem" }} black margin="10px 0">
          {domToReact(children, postConfig)}
        </P>
        </div>
      )
    }
    if (name && name === "img") {
      return (
        <ArticleImage
          src={attribs.src}
          alt={attribs.alt}
          width={attribs.width}
        />
      )
    }
    if (name && name === "h2") {
      return (
        <H2 gray margin="10px 0">
          {domToReact(children, postConfig)}
        </H2>
      )
    }
    if (name && name === "h3") {
      return (
        <H3 style={{ color: "#575757",fontSize:"21px" }} margin="10px 0">
          {domToReact(children, postConfig)}
        </H3>
      )
    }
    if (name && name === "h4") {
      return (
        <H2 gray style={{ fontSize:"20px" }} margin="10px 0">
          {domToReact(children, postConfig)}
        </H2>
      )
    }
    if (name && name === "h5") {
      return (
        <H3 style={{ color: "#575757",fontSize:"45px",textAlign:"center" }} margin="10px 0">
          {domToReact(children, postConfig)}
        </H3>
      )
    }
    if (name && name === "li") {
      return <li style={{ color: "black" }} >{domToReact(children, postConfig)}</li>
    }
  },
}

const careerConfig = {
  replace: ({ name, children }) => {
    if (name && name === "p") {
      return (
        <div className="careerp">
        <P style={{ fontSize: "1.25rem" }} margin="0 0 20px 0">
          {domToReact(children, careerConfig)}
        </P>
        </div>
      )
    }
    if (name && name === "li") {
      return <li style={{ marginLeft:"20px" }} >{domToReact(children, careerConfig)}</li>
    }

    if (name && name === "div") {
      return <div style={{ marginLeft:"20px" }} >{domToReact(children, careerConfig)}</div>
    }

    if (name && name === "h2") {
      return <H2 margin="10px 0 -20px 0">{domToReact(children, careerConfig)}</H2>
    }
    if (name && name === "h3") {
      return <H3 margin="10px 0 -20px 0">{domToReact(children, careerConfig)}</H3>
    }
  },
}

const ParseHTML = html => {
  const clean = parse(html, postConfig)
  return clean
}

export const ParseCareersHTML = html => {
  const clean = parse(html, careerConfig)
  return clean
}

export default ParseHTML

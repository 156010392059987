import styled from "@emotion/styled"
import { Link } from "gatsby"
import Img from "gatsby-image"

export const TopTacticsList = styled.div`
  grid-column: 1 / span 8;
  display: grid;
  gap: 10rem 0;
  margin-bottom: 100px;
  @media ${props => props.theme.breakpoints.medium} {
    gap: 10rem 0;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    gap: 6rem 0;
    margin-bottom: 100px;
  }
`
export const TopTacticsCardWrapper = styled.div`
  position: relative;
  .top-tactics__category {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 3;
    @media ${props => props.theme.breakpoints.medium} {
      right: 13%;
      left: auto;
    }
    @media ${props => props.theme.breakpoints.large} {
      right: 150px;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    height: 35.8125rem;
  }
`
export const PostCategoryWrapper = styled.div`
  align-items: center;
  background: white;
  display: inline-flex;
  color: ${props => props.theme.colors.purple};
  font-weight: bold;
  letter-spacing: 0.3rem;
  padding: 18px 50px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  .icon {
    width: 18px;
    height: 18px;
  }
`

export const TopTacticsCardCTAWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 25px;
  width: 310px;
  top: 70%;
  left: 0;
  margin: auto;
  right: 0;
  transition: all 0.5s ease-out;
  z-index: 3;
  background: white;
  h1 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 35rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    top: 50%;
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    height: 17.9375rem;
    bottom: 0;
    padding: 47px;
    top: auto;
    left: auto;
    right: 0;
    width: 728px;
    transform: translateX(-20%);
    h1 {
      font-size: 35px;
    }
  }
  &:hover {
    background: ${props => props.theme.colors.purple};
    color: white;
    p,
    h1 {
      color: white;
    }
    svg {
      color: white;
    }
  }
`

export const ToTacticsCardInfo = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`

export const TopTacticsCardTitle = styled(Link)`
  display: flex;
  transition: all 0.5s;
  flex-direction: column;
  background: white;
  padding: 0 2rem 2rem 2rem;
  h1 {
    font-size: 1.125rem;
    margin-bottom: 1.5625rem;
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    padding: 2rem;
    h1 {
      font-size: 2.875rem;
      line-height: 50px;
      margin-bottom: 2.8125rem;
    }
    &:hover {
      background: ${props => props.theme.colors.purple};
      h1 {
        color: white;
      }
      .button__children {
        color: white;
      }
    }
  }
`

export const TopTacticsCardImageOverlay = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

export const TopTacticsCardImage = styled(Img)`
  position: absolute;
  width: 100%;
  height: 430px;
  z-index: 1;
  top: 0;
  left: 0;
`

export const TacticsList = styled.div`
  display: grid;
  margin-top: 50px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 52px;
`
export const TacticsCardWrapper = styled(Link)`
  display: block;
  background: white;
  .tactics__category {
    position: absolute;
    top: 22px;
    left: 23px;
    z-index: 2;
  }
`
export const TacticsCardDetails = styled.div`
  padding: 25px;
  height: 250px;
  transition: all 0.5s;
  &:hover {
    background: ${props => props.theme.colors.purple};
    h1,
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }
`
export const TacticsCardImage = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

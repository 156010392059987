import React from "react"
import { H1, P, ToTacticsCardInfo } from "../elements"
import IconArticle from "../images/icon-book.svg"
import IconCalendar from "../images/icon-calendar.svg"
import IconUser from "../images/icon-user.svg"

const Icons = {
  article: IconArticle,
  user: IconUser,
  calendar: IconCalendar,
}

export const FrontMatter = ({ title, date, author }) => {
  return (
    <div style={{ marginBottom: 50, display: "flex", flexDirection: "column" }}>
      <H1 margin="0 0 15px 0" large black>
        {title}
      </H1>
      <ToTacticsCardInfo>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginRight: 30,
          }}
        >
          <PostIcon name="user" />
          <P uppercase black size="xSmall" weight="bold">
            {author.name}
          </P>
        </div>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginRight: 30,
          }}
        >
          <PostIcon name="calendar" />
          <P uppercase black size="xSmall" weight="bold">
            {date}
          </P>
        </div>
      </ToTacticsCardInfo>
    </div>
  )
}
const PostIcon = ({ name }) => {
  if (Icons[name] === undefined) return null
  const Icon = Icons[name]
  return (
    <span style={{ marginRight: 4, color: "black" }}>
      <Icon />
    </span>
  )
}

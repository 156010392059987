import React from "react"

import { ScoreCardWrapper, P, ScoreCardNumber } from "../elements"

export const ScoreCard = ({ score }) => {
  const { title, value, description, quote } = score
  return (
    <ScoreCardWrapper className="scorecard">
      <P uppercase margin="0 0 23px 0">
        {title}
      </P>
      <ScoreCardNumber large>{value}</ScoreCardNumber>
      <P style={{ color: "#B5B5B5", fontWeight: "900", lineHeight: "1.5" , marginBottom:"15px"}}>
        {quote}
      </P>
      <P>{description}</P>
    </ScoreCardWrapper>
  )
}

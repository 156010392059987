import styled from "@emotion/styled"
import Img from "gatsby-image"

export const SlideWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`

export const AboutSlideWrapper = styled.div`
  height: 100%;
  display: block;
  position: relative;

`

export const AboutSlideImage = styled(Img)`
  height: 35vw !important;
  width: 100%;
  object-fit: cover;
  object-position: top !important;
  @media (max-width: 657px) {
    height: 440px !important;
  }
`

export const AboutSlideInfoBox = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px 0px 40px;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: auto;
  @media ${props => props.theme.breakpoints.medium} {
    width: 100%;
    height: auto;
    padding: 0px 100px 10px 100px;
  }
`

export const SlideImage = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
`

export const SlideInfoBox = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  width: 308px;
  position: absolute;
  z-index: 1;
  height: auto;
  @media ${props => props.theme.breakpoints.medium} {
    width: 349px;
    height: auto;
    padding: 28px 40px;
  }
`

export const CarouselWrapper = styled.div`
  grid-column: 1 / span 8;
  position: relative;
  @media ${props => props.theme.breakpoints.medium} {
    grid-column: 1 / span 8;
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
  }
  .buttonBack {
    display: none;
    position: absolute;
    top: 50%;
    left: 38px;
    transform: translateY(-50%);
    &:hover {
      svg {
        g {
          path {
            fill: ${props => props.theme.colors.purple};
            transition: all 0.3s ease-out;
          }
        }
      }
    }
    @media ${props => props.theme.breakpoints.medium} {
      display: block;
    }
  }
  .buttonNext {
    display: none;
    position: absolute;
    top: 50%;
    right: 38px;
    transform: translateY(-50%);
    &:hover {
      svg {
        g {
          path {
            fill: ${props => props.theme.colors.purple};
            transition: all 0.3s ease-out;
          }
        }
      }
    }
    @media ${props => props.theme.breakpoints.medium} {
      display: block;
    }
  }
`

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import ReactPlayer from "react-player"
import "../../node_modules/react-modal-video/scss/modal-video.scss"
import {
  H1,
  HeroContentWrapper,
  HeroImage,
  HeroOverlay,
  HeroWrapper,
  P,
  Strike,
} from "../elements"
import { Button } from "./Button"

export const Hero = ({
  videoUrl,
  hasBgVideo = false,
  title,
  videoId,
  strikeWord,
  buttonTitle = "Play Video",
  hasOverlay = true,
  description,
  image,
}) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fluid: { originalName: { eq: "hero-background.png" } }) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
  }
  return (
    <HeroWrapper hasBgVideo={hasBgVideo}>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />

      <HeroOverlay hasOverlay={hasOverlay}>
        <HeroContentWrapper>
          {title && (
            <H1 margin={description ? "0" : "0 0 35px 0"} large>
              {title} {strikeWord && <Strike large>{strikeWord}</Strike>}
            </H1>
          )}

          {description && (
            <P margin="2rem 0" size="large">
              {description}
            </P>
          )}
          {videoId && (
            <Button onClick={openModal} icon="play">
              {buttonTitle}
            </Button>
          )}
        </HeroContentWrapper>
      </HeroOverlay>
      <ReactPlayer
        style={{
          position: "absolute",
          top: 0,
          zIndex: 2,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        width="100%"
        loop={true}
        playing={isOpen ? false : true}
        volume={0}
        muted={true}
        height="100%"
        url={videoUrl}
      />
      <HeroImage fluid={image ? image : data.imageSharp.fluid} />
    </HeroWrapper>
  )
}

import styled from "@emotion/styled"
import { Link } from "gatsby"

const buttonBackground = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.colors.purple) return "#00FFFF"
  // If no variant is specified, return the primary variant in our theme
  if (!props.variant) return props.theme.colors.purple

  // Dynamically determine the background variant based on props
  let color
  switch (props.variant) {
    case "light":
      color = "transparent"
      break
    case "solid":
      color = "white"
      break
    default:
      color = "transparent"
      break
  }

  return color
}

const buttonPadding = props => {
  if (!props) return "12px 16px"
  let padding
  if (props.name) {
    padding = "0"
  } else {
    padding = "12px 16px"
  }

  return padding
}

const buttonBorder = props => {
  if (!props) return "2px solid white"
  let border
  if (props.name) {
    border = "none"
  } else {
    border = "2px solid white"
  }
  if (props.variant === "dark") {
    border = "2px solid black"
  }
  if (props.variant === "dark" && props.name) {
    border = "none"
  }

  return border
}
const buttonColor = props => {
  if (!props) return "white"
  let color
  switch (props.variant) {
    case "solid":
      color = "black"
      break
    case "dark":
      color = "black"
      break
    default:
      color = "white"
      break
  }

  return color
}
const buttonWidth = props => {
  if (!props) return "150px"
  let width
  if (props.name) {
    width = "200px"
  } else {
    width = "150px"
  }
  switch (props.width) {
    case "small":
      width = "150px"
      break
    case "medium":
      width = "850px"
      break
    case "large":
      width = "100%"
      break
    default:
      width = "150px"
      break
  }

  return width
}

export const StyledButton = styled.button`
  align-items: center;
  display: inline-flex;
  height: ${props => (props.name ? "auto" : "40px")};
  padding: ${props => buttonPadding(props)};
  position: relative;
  background: ${props => buttonBackground(props)};
  border: ${props => buttonBorder(props)};
  color: ${props => buttonColor(props)};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.button};
  font-size: 0.875rem;
  line-height: 1;
  max-width: ${props => buttonWidth(props)};
  margin: ${props => (props.margin ? props.margin : "0")};
  outline: none;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.15s ease;
  text-transform: uppercase;
  white-space: ${props => (props.name ? "auto" : "nowrap")};
  width: 100%;
  justify-content: ${props => (props.name ? "start" : "center")};
  .button__icon {
    margin-right: 10px;
    g {
      path {
        transition: all 0.15s ease;
      }
    }
  }
  .button__children {
    align-items: center;
    text-align: ${props => (props.name ? "start" : "center")};
    display: inline-flex;
    z-index: 2;
    P {
      margin-top: ${props => (props.name ? "0" : "4px")};
    }
  }
  &:before {
    background-color: ${props =>
      props.name ? "none" : props.theme.colors.purple};
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    width: 100%;
    z-index: 1;
  }
  &:hover {
    border-color: ${props => (props.name ? "none" : props.theme.colors.purple)};
    color: white;
    &:before {
      transition: all 0.5s ease-out;
      transform: scale(3);
    }
    .button__icon {
      g {
        path {
          fill: ${props =>
            props.name === "play" ? props.theme.colors.purple : ""};
        }
      }
    }
  }
`

export const StyledLinkButton = StyledButton.withComponent(Link)

export const StyledExternalLinkButton = StyledButton.withComponent("a")

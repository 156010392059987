import Img from "gatsby-image"
import React from "react"
import { Button } from "../components"
import { P } from "../elements"
import {
  ResultCardDetailsWrapper,
  ResultCardImageWrapper,
  ResultCardWrapper,
} from "../elements/ResultsCardElements"

export const ResultCard = ({ result }) => {
  const { title, slug, featured_media } = result
  return (
    <ResultCardWrapper to={`/results/${slug}`}>
      <ResultCardImageWrapper>
        <Img
          fluid={featured_media.localFile.childImageSharp.fluid}
          className="result-image"
        />
      </ResultCardImageWrapper>
      <ResultCardDetailsWrapper>
       {/* <P
          size="small"
          uppercase
          style={{ color: "#B5B5B5", fontWeight: "bold" }}
        >
          Television Jamaica
       </P> */}
        <P
          margin="15px 0"
          style={{
            fontFamily: "itc-avant-garde-gothic-pro, sans-serif",
          }}
        >
          {title}
        </P>
        <Button to={`/results/${slug}`}>See Results</Button>
      </ResultCardDetailsWrapper>
    </ResultCardWrapper>
  )
}

import styled from "@emotion/styled"

export const CardWrapper = styled.div`
  width: 100%;

  margin: ${props => (props.margin ? props.margin : "0")};
  @media ${props => props.theme.breakpoints.extralarge} {
    display: flex;
  }
`
export const CardTitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 35px;
  @media ${props => props.theme.breakpoints.extralarge} {
    width: 25%;
    margin-bottom: 0;
  }
  p {
    text-transform: capitalize;
  }
`

export const CardContentWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: ${props => {
    switch (props.grid) {
      case "2x2":
        return "none"
      case "2x4":
        return "auto auto"
      default:
        return "none"
    }
  }};

  gap: ${props => {
    switch (props.grid) {
      case "2x2":
        return "3.75rem 1.25rem"
      case "2x4":
        return "2rem 2rem"
      default:
        return "2rem 0"
    }
  }};

  @media ${props => props.theme.breakpoints.extralarge} {
    width: 75%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: ${props => {
      switch (props.grid) {
        case "2x2":
          return "1fr 1fr"
        case "2x4":
          return "auto auto auto auto"
        default:
          return "none"
      }
    }};
    grid-template-rows: ${props => {
      switch (props.grid) {
        case "2x2":
          return "auto auto"
        case "2x4":
          return "auto auto"
        default:
          return "none"
      }
    }};
  }
`
export const CardTitle = styled.p`
  margin: 0 auto;
  font-size: 1rem;
  color: ${props => (props.black ? "black" : "white")};
  font-family: ${props => props.theme.fonts.heading};
  &:before {
    content: "";
    display: block;
    width: 100px;
    padding-bottom: 10px;
    border-top: 1px solid ${props => (props.black ? "black" : "white")};
  }
`

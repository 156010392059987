import { array, string } from "prop-types"
import React from "react"
import { StyledSocialIcon, StyledSocialIconGrouped } from "../elements"
import IconFacebook from "../images/facebook.svg"
import IconInstagram from "../images/instagram.svg"
import IconLinkedin from "../images/linkedin.svg"
import IconTwitter from "../images/twitter.svg"
import IconYoutube from "../images/youtube.svg"

const Icons = {
  facebook: IconFacebook,
  instagram: IconInstagram,
  linkedin: IconLinkedin,
  twitter: IconTwitter,
  youtube: IconYoutube,
}

export const SocialIcon = ({ name, href, marginTop }) => {
  if (Icons[name] === undefined) return null
  const Icon = Icons[name]
  let className
  switch (name) {
    case "instagram":
      className = "social__icon"
      break
    case "youtube":
      className = "social__icon"
      break
    default:
      className = "social__icon filled"
      break
  }

  return (
    <StyledSocialIcon marginTop={marginTop} href={href} target="_blank">
      <Icon className={className} />
    </StyledSocialIcon>
  )
}

SocialIcon.propTypes = {
  name: string.isRequired,
  href: string.isRequired,
}

export const SocialIconGrouped = ({ icons, marginTop }) => {
  if (icons === undefined) return null
  return (
    <StyledSocialIconGrouped>
      {icons.map((icon, index) => (
        <SocialIcon
          key={index}
          marginTop={marginTop}
          name={icon.name}
          href={icon.href}
        />
      ))}
    </StyledSocialIconGrouped>
  )
}

SocialIconGrouped.propTypes = {
  icons: array.isRequired,
}

export default SocialIcon

import Img from "gatsby-image"
import React from "react"
import { Button } from "../components"
import {
  H1,
  P,
  ServiceCardContentWrapper,
  ServiceCardImageWrapper,
  ServiceCardTitleWrapper,
  ServiceCardWrapper,
} from "../elements"

export const ServiceCard = ({
  index,
  name,
  image,
  abbreviation,
  description,
  toggleButton,
}) => {
  return (
    <ServiceCardWrapper>
      <ServiceCardImageWrapper>
        <ServiceCardTitleWrapper>
          {/* <P number={index}>{abbreviation}</P> */}
          {console.log("serviceimg"+index)}

          <H1 style={{ width: "273px" }} className="servicetitles" large>
            {name}
          </H1>
        </ServiceCardTitleWrapper>
        <Img style={{ height: "100%" }} fluid={image} className={"serviceImg "+("serviceImg"+index)}/>
      </ServiceCardImageWrapper>
      <ServiceCardContentWrapper>
        <P margin="0 0 35px 0">{description}</P>
        {toggleButton && <Button to="/services">Find Out More</Button>}
      </ServiceCardContentWrapper>
    </ServiceCardWrapper>
  )
}

import React from "react"
import { P } from "../elements"
import ParseHTML from "../utils/ParseHTML"
import DOMPurify from 'dompurify'

const ReachOutCard = ({ title = "Title Here", description, tel, email}) => {
  return (
    <div className="reachoutcard">
      <P size="large" weight="bold">
        {title}
      </P>

      <div className="reachoutp" dangerouslySetInnerHTML={{ __html:description }}/>
      <P weight="bold">{email}</P>
      <P weight="bold">{tel}</P>
    </div>
  )
}

export default ReachOutCard

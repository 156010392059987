import { FormControlLabel, FormHelperText, MenuItem } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { Button } from "../components"
import { FormWrapper, IPPCheckbox, P } from "../elements"
import useForm from "../hooks/useForm"
import validate from "../utils/validateForm"
import { useEffect, useState } from "react"
export const ContactForm = () => {
  const { handleChange, handleSubmit, values, terms, errors } = useForm(
    submit,
    validate
  )
  function submit() {
    console.log("success")
  }

  const [show, setMsg] = useState({
    msg:'',
    submitted:false
  })

  const options = [
    {
      value: "Search Engine",
      label: "Search Engine",
    },
    {
      value: "Ads",
      label: "Ads",
    },
    {
      value: "Social media",
      label: "Social media",
    },
    {
      value: "Referral from another",
      label: "Referral from another",
    },
  ]

  function clickd() {

    if (Object.keys(errors).length === 0){
        setMsg({
          msg: 'Form submitted successfully!',
          submitted:true
        })
    }
    else
    {
      setMsg({
        msg: 'Please fill out all fields correctly!',
        submitted:true
      })
    }
  }


  return (
    <FormWrapper onSubmit={handleSubmit} noValidate autoComplete="off">
      <TextField
        className="text-field"
        error={show.submitted && errors.name ? true : false}
        onChange={handleChange}
        required
        value={values.name}
        name="name"
        helperText={show.submitted && errors.name ? errors.name : ""}
        label="Your Name"
      />
      <TextField
        className="text-field"
        error={show.submitted && errors.email ? true : false}
        onChange={handleChange}
        required
        value={values.email}
        helperText={show.submitted && errors.email ? errors.email : ""}
        name="email"
        label="Email Address"
      />
      <TextField
        className="text-field full-width"
        error={show.submitted && errors.message ? true : false}
        name="message"
        onChange={handleChange}
        value={values.message}
        required
        helperText={show.submitted && errors.message ? errors.message : ""}
        multiline
        label="Your Message"
      />
      <P weight="bold" size="large" id="findout">
        How Did You Find Out About Us?
      </P>
      <TextField
        className="text-field"
        name="aboutUs"
        required
        select
        value={values.aboutUs}
        onChange={handleChange}
        label="Select"
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <FormControlLabel
        className="full-width"
        control={
          <IPPCheckbox
            value={values.terms}
            required
            onChange={handleChange}
            name="terms"
          />
        }
        label="I agree to receive occasional It’s Pixel Perfect newsletters containing news and advice on creating personal and business progress through digital transformation, experience and marketing."
      />
      {errors.terms && <FormHelperText error>{show.submitted && errors.terms ? errors.terms : ""}</FormHelperText>}

      <FormHelperText>{show.msg}</FormHelperText>

      <Button className="submit-button contsub" variant="solid" onClick={clickd}>
        Submit
      </Button>
      <P className="full-width" size="xSmall">
        We will use your contact details to contact you directly about potential
        cooperation opportunities now or in the future.
      </P>
    </FormWrapper>
  )
}

import styled from "@emotion/styled"

export const FooterWrapper = styled.footer`
  grid-column: 1 / span 8;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.gray2};
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1 / span 14;
    padding-left: 9.5rem;
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    height: 36.125rem;
    padding-left: 23.5rem;
  }
`

export const FooterContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 1rem;
  h1 {
    font-size: 24px;
  }
  @media ${props => props.theme.breakpoints.medium} {
    margin: 0 3rem;
    h1 {
      font-size: 42px;
      line-height: 48px;
    }
  }
  @media ${props => props.theme.breakpoints.extralarge} {
    margin: 0;
    h1 {
      font-size: 54px;
      line-height: 60px;
    }
  }
`

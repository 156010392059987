import React from "react"

import { CapabilityCardWrapper, P } from "../elements"

export const CapabilityCard = ({ capability }) => {
  const { name, listings } = capability
  return (
    <CapabilityCardWrapper>
      <P
        size="large"
        weight="bold"
        style={{ color: "#6F6F6F" }}
        margin="0 0 23px 0"
      >
        {name}
      </P>
      <ul>
        {listings.map((item, index) => (
          <li key={index} className="liservices" style={{ marginBottom: 11 }}>
            <P>{item}</P>
          </li>
        ))}
      </ul>
    </CapabilityCardWrapper>
  )
}

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import "../../node_modules/react-modal-video/scss/modal-video.scss"
import { Button } from "../components"
import {
  CareersNamePositionWrapper,
  CareersVideoCardOverlay,
  CareersVideoCardThumb,
  CareersVideoCardWrapper,
  CareersVideosContentWrapper,
  CareersVideoWrapper,
  P,
} from "../elements"

export const CareerVideos = () => {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true);
  }

  const data = useStaticQuery(graphql`
    query {
      melDesktop: file(relativePath: { eq: "mel-thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 840, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      melMobile: file(relativePath: { eq: "mel-thumb2.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gemDesktop: file(relativePath: { eq: "gem-thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 840, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gemMobile: file(relativePath: { eq: "gem-thumb2.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gem: file(relativePath: { eq: "gem-thumb.png" }) {
        publicURL
      }
      mel: file(relativePath: { eq: "mel-thumb2.png" }) {
        publicURL
      }
    }
  `)
  const melSources = [
    data.melMobile.childImageSharp.fluid,
    {
      ...data.melDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const gemSources = [
    data.gemMobile.childImageSharp.fluid,
    {
      ...data.gemDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <CareersVideoWrapper>
      <CareersVideoCardWrapper>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IhOhkNK5yS4"
          onClose={() => setIsOpen(false)}
        />
        <CareersVideoCardOverlay>
          <CareersVideosContentWrapper>
            <Button onClick={openModal} icon="play">
              Watch the Clip
            </Button>
            <CareersNamePositionWrapper>
              <P weight="bold">Melissa Chong Sang</P>
              <P>Experience Officer</P>
            </CareersNamePositionWrapper>
          </CareersVideosContentWrapper>
        </CareersVideoCardOverlay>
        <CareersVideoCardThumb fluid={melSources} alt="Melissa Chong Sang IPP" />
      </CareersVideoCardWrapper>

      <CareersVideoCardWrapper>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="RMxhYgJPCeg"
          onClose={() => setIsOpen(false)}
        />
        <CareersVideoCardOverlay>
          <CareersVideosContentWrapper>
            <Button onClick={openModal} icon="play">
              Watch the Clip
            </Button>
            <CareersNamePositionWrapper>
              <P weight="bold">Gemmar McFarlane</P>
              <P>Video Editor / Director</P>
            </CareersNamePositionWrapper>
          </CareersVideosContentWrapper>
        </CareersVideoCardOverlay>
        <CareersVideoCardThumb fluid={gemSources} alt="Gemmar McFarlane IPP" />
      </CareersVideoCardWrapper>
    </CareersVideoWrapper>
  )
}
